import * as PlanListAction from './action';
import { PlanListAdapter, orderInitialState, PlanListState } from './state';

export function PlanListReducer(state: PlanListState = orderInitialState, action: PlanListAction.Actions) {
  switch (action.type) {
    case PlanListAction.ADD_PlanList:
      return {
        ...PlanListAdapter.addOne(action.payload, state)
      };
    case PlanListAction.REMOVE_PlanList:
      return {
        ...PlanListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    case PlanListAction.UPDATE_PlanList:

      return {
        ...PlanListAdapter.updateOne({ id: action.payload['id'], changes: action.payload }, state)
      };
    case PlanListAction.POST_PlanList:
      return {
        ...PlanListAdapter.removeAll(state),
        isLoading: false,
        error: null
      };
    default:
      return state;
  }
}

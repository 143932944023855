import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ReplaceUnderscorePipe, TokenGuard } from '../services';
import { RouterModule, Routes } from '@angular/router';

import { CommonModule } from '@angular/common';
import { DashboardComponent } from './dashboard/dashboard.component';
import { MaterialModule } from '../route-file/material.module';
import { MyDatePickerModule } from 'mydatepicker';
import { NgModule } from '@angular/core';
import { NgxFullCalendarModule } from 'ngx-fullcalendar';
import { NgxPaginationModule } from 'ngx-pagination';
import { OrderModule } from 'ngx-order-pipe';

const routes: Routes = [
  { path: '', component: DashboardComponent },
];


const BASE_MODULES = [
  CommonModule,
  FormsModule,
  ReactiveFormsModule,
  NgxFullCalendarModule,
  NgxPaginationModule,MyDatePickerModule,
  MaterialModule
];

@NgModule({
  imports: [RouterModule.forChild(routes)],
  exports: [RouterModule]
})
@NgModule({
  imports: [...BASE_MODULES,RouterModule.forChild(routes),OrderModule],
  exports: [RouterModule],
})
export class HomedashboardRoutingModule { }

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { ReStopList } from '../../modal';

export interface ReStopListState extends EntityState<ReStopList> {
    isLoading: boolean;
    selectedReStopListId: any;
    error: any;
}

export const ReStopListAdapter: EntityAdapter<ReStopList> = createEntityAdapter<ReStopList>({
  selectId: (editorder: ReStopList) => 'data',
  sortComparer: false,
});

export const orderInitialState: ReStopListState = ReStopListAdapter.getInitialState({
  isLoading: true,
  selectedReStopListId: null,
  error: null
});

export const selectedReStopListId = (state: ReStopListState) => state.entities;
export const selectIsLoading = (state: ReStopListState) => state.isLoading;
export const selectError = (state: ReStopListState) => state.error;

import { Component, HostListener, OnInit } from '@angular/core';
import { MatSidenav } from '@angular/material';
import { Router } from '@angular/router';
import { CommonService, SharedserviceService } from 'src/app/services';
import { NgxUiLoaderService } from 'ngx-ui-loader';
import { FormsModule } from '@angular/forms';
// import { getMessaging, getToken, onMessage } from "firebase/messaging";
import { environment } from "../../../environments/environment";
import { ToastrManager } from 'ng6-toastr-notifications';
declare var $: any;

@Component({
  selector: 'app-dashmaster',
  templateUrl: './dashmaster.component.html',
  styleUrls: ['./dashmaster.component.scss'],
  providers: [NgxUiLoaderService],
  host: {
    "(window:resize)": "onWindowResize($event)"
  }
})
export class DashmasterComponent implements OnInit {
  dummydata:any = [];
  b2c_login:boolean;  book_outside:boolean;  show_outside:boolean;  scroll_msg_inside:string;  scroll_msg_outside:string;
  searchtext:any; Enable_set_var:any=false; WebSite_set_data:any;
  isSticky: boolean = false;
  sidenav: any;
  user_id: any;
  role_id: any;
  user: any;
  isMobile: boolean = false;
  width: number = window.innerWidth;
  mobileWidth: number = 500;
  public innerWidth: any;

  title = 'fareboutique';
  message:any = null;
  browser:any;
  clrCode1: boolean;
  clrCode2: boolean;
  clrCode3: boolean;
  clrCode4: boolean;
  clrCode5: boolean;
  themeActive: boolean;
  settingdata: any;
  colortheme: any;
  logotheme: any;
  wesiteThemeData: any;

  constructor(
    private router: Router,
    private toastr: ToastrManager,
    public ngxloader: NgxUiLoaderService,
    public cds: CommonService,
    public sharedService: SharedserviceService,) {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user != null) {
      this.user_id = this.user.id;
      this.role_id = this.user.role_id;
    }
  }

  ngOnInit():void {
    setTimeout(() => {
      this.ngxloader.start();
      if(localStorage.getItem("web_setting_data")!= "" && localStorage.getItem("web_setting_data") != undefined && localStorage.getItem("web_setting_data") !=null){
        this.wesiteThemeData = JSON.parse(localStorage.getItem("web_setting_data"));
        document.documentElement.style.setProperty(
          "--theme-color",
          this.wesiteThemeData.bg_color
          );
          this.colortheme = this.wesiteThemeData.bg_color;
          this.logotheme = this.wesiteThemeData.logo_url;

          this.ngxloader.stop();
        }else{
          // this.getWebsiteThemeData();
        }
      }, 300);
    // console.log(location.host,'location');

    this.requestPermission();
    this.listen();

    this.innerWidth = window.innerWidth;
    if(this.innerWidth < 769){
      this.isExpanded = false;
    }
    else{
      this.isExpanded = true
    }


    this.isMobile = this.width < this.mobileWidth;
    window.addEventListener('scroll', this.scroll, true);
  }

  // getWebsiteThemeData() {
  //   var requestdata = {
  //     domain: "fareboutique.com",
  //     // domain: location.host,
  //   }

  //   this.sharedService.post('v1/domain_check', requestdata).subscribe((res) => {
  //     if (res.replyCode == 'success') {
  //       if ((res.data).length != 0) {
  //         localStorage.setItem("web_setting_data", JSON.stringify(res.data[0]));
  //         this.colortheme = res.data[0].bg_color;
  //         this.logotheme = res.data[0].logo_url;

  //         document.documentElement.style.setProperty(
  //           "--theme-color",
  //           res.data[0].bg_color
  //         );
  //       }
  //     } else {
  //       this.toastr.errorToastr(res.replyMsg, "Error");
  //     }
  //   }, error => {
  //     this.toastr.errorToastr(error.error.replyMsg, "Error");
  //   });
  // }

  gotoinventory(val) {
    var val1 = 'dashboard'
    this.ngxloader.start();
    this.router.navigateByUrl(val);
  }

  onWindowResize(event) {
    this.width = event.target.innerWidth;
    this.isMobile = this.width < this.mobileWidth;
    if (this.isMobile = this.width < this.mobileWidth) {
      this.isMobile = true;
      this.isExpanded = false;
    }
    else {
      this.isMobile = false;
      this.isExpanded = true;
    }
  }

  logout() {
    // localStorage.clear();
    sessionStorage.clear();
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.router.navigateByUrl('firstpage')
  }

  isExpanded = true;
  showSubmenu: boolean = false;
  showSubmenu1: boolean = false;
  showSubmenu2: boolean = false;
  showSubmenu3: boolean = false;
  showSubmenu4: boolean = false;
  isShowing = false;
  showSubSubMenu: boolean = false;

  mouseenter() {
    if (!this.isExpanded) {
      this.isShowing = true;
    }
  }

  mouseleave() {
    if (!this.isExpanded) {
      this.isShowing = false;
    }
  }
  golink(){
    console.log("line 109 :::::::::",this.searchtext)
    if(this.searchtext != ""){
      console.log("if undefined ")
      if(this.searchtext == undefined){
        this.searchtext="";
      }
      sessionStorage.setItem("sidebar_searchtext", JSON.stringify(this.searchtext))
      this.router.navigateByUrl('/dashboard/search-result');
    }
  }

  show_Website_setting(){
    $('#web_set').modal('show');
    var self = this;
    self.sharedService.get('v1/website_setting/view').subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.Enable_set_var=false;
        console.log("res.data",res.data)
        this.WebSite_set_data=res.data;
        this.b2c_login=res.data.b2c_login;
        this.book_outside=res.data.book_outside;
        this.show_outside=res.data.show_outside;
        this.scroll_msg_inside=res.data.scroll_msg_inside;
        this.scroll_msg_outside=res.data.scroll_msg_outside;
        this.toastr.successToastr(res.replyMsg,'Success')
      }
    },err =>{
      this.Enable_set_var=true;
      // this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  b2c_onOff(event){ this.b2c_login=event }

  book_outside_onOff(event){  this.book_outside=event  }

  show_onOff(event){ this.show_outside=event }

  Edit_setting(){
    var self = this;
    var request={
      scroll_msg_outside: this.scroll_msg_outside,
      scroll_msg_inside: this.scroll_msg_inside,
      show_outside: this.show_outside,
      book_outside: this.book_outside,
      b2c_login: this.b2c_login
    }
    self.sharedService.post('v1/website_setting/edit',request).subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg,'Success')
        $('#web_set').modal('hide');
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  Enable_set(){
    var self = this;
    self.sharedService.get('v1/website_setting/insertData').subscribe((res:any)=>{
      if(res.replyCode == 'success'){
        this.toastr.successToastr(res.replyMsg,'Success')
      }
    },err =>{
      this.toastr.errorToastr(err.replyMsg,'Error')
    })
  }

  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
  }

  scroll = (event): void => {
    this.isSticky = window.pageYOffset >= 20;
  };


  requestPermission() {

    // const messaging = getMessaging();

    // getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
    //   if (currentToken) {
    //     console.log("Hurraaa!!! we got the token.....")
    //     console.log(currentToken);
    //     // Send the token to your server and update the UI if necessary
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // });

  }
  listen() {
    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   this.message=payload;
    //   console.log('Message received new::::::. ', this.message);
    // });
  }

  themeCode1(code1){
    console.log("checkcode ;;;;;;;;;;",code1);

    if(code1==1){
      this.clrCode1=true;this.clrCode2=false;this.clrCode3=false;this.clrCode4=false;this.clrCode5=false;
      sessionStorage.clear();
      sessionStorage.setItem("clrChange1",JSON.stringify(this.clrCode1));
      this.themeActive = false;
     }
    else if(code1==2){ this.clrCode2=true; this.clrCode1=false;this.clrCode3=false;this.clrCode4=false;this.clrCode5=false;
      sessionStorage.clear();
      sessionStorage.setItem("clrChange2",JSON.stringify(this.clrCode2));
      this.themeActive = false;
    }
    else if(code1==3){ this.clrCode3=true; this.clrCode2=false;this.clrCode1=false;this.clrCode4=false;this.clrCode5=false;
      sessionStorage.clear();
      sessionStorage.setItem("clrChange3",JSON.stringify(this.clrCode3));
      this.themeActive = false;
    }
    else if(code1==4){ this.clrCode4=true; this.clrCode2=false;this.clrCode3=false;this.clrCode1=false;this.clrCode5=false;
      sessionStorage.clear();
      sessionStorage.setItem("clrChange4",JSON.stringify(this.clrCode4));
      this.themeActive = false;
    }
    else if(code1==5){ this.clrCode5=true; this.clrCode2=false;this.clrCode3=false;this.clrCode4=false;this.clrCode1=false;
      sessionStorage.clear();
      sessionStorage.setItem("clrChange5",JSON.stringify(this.clrCode5));
      this.themeActive = false;
    }
    // if(code1==1){ this.clrCode1= this.theme1; }
    // else if(code1==2){ this.clrCode2= '#3f9ab5'; }
    // else if(code1==3){ this.clrCode3= '#3fb584'; }
    // else if(code1==4){ this.clrCode4= '#e77f27'; }
    // else if(code1==5){ this.clrCode5= '#181818'; }
  }
}


import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Pnr } from '../../modal';

export interface PnrState extends EntityState<Pnr> {
    isLoading: boolean;
    selectedPnrId: any;
    error: any;
}

export const PnrAdapter: EntityAdapter<Pnr> = createEntityAdapter<Pnr>({
  selectId: (editorder: Pnr) => 'data',
  sortComparer: false,
});

export const orderInitialState: PnrState = PnrAdapter.getInitialState({
  isLoading: true,
  selectedPnrId: null,
  error: null
});

export const selectedPnrId = (state: PnrState) => state.entities;
export const selectIsLoading = (state: PnrState) => state.isLoading;
export const selectError = (state: PnrState) => state.error;

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { FareReducer } from './reducer';
import { FareEffects } from './effect';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('Fare', FareReducer),
    EffectsModule.forFeature([FareEffects])
  ],
  exports: [StoreModule, EffectsModule],
  providers: []
})
export class FareStoreModule {}

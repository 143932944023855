import { NgModule, CUSTOM_ELEMENTS_SCHEMA } from '@angular/core';
import { CommonModule } from '@angular/common';
import { AdminlayoutRoutingModule } from './adminlayout-routing.module';
import { AdminlayoutComponent } from './adminlayout.component';
import { HeaderComponent } from './header/header.component';
import { SidebarComponent } from './sidebar/sidebar.component';
import { AdminmasterComponent } from './adminmaster/adminmaster.component';
@NgModule({
  declarations: [AdminlayoutComponent, HeaderComponent, SidebarComponent, AdminmasterComponent],
  imports: [ CommonModule,AdminlayoutRoutingModule],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AdminlayoutModule { }

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-adminmaster',
  templateUrl: './adminmaster.component.html',
  styleUrls: ['./adminmaster.component.scss']
})
export class AdminmasterComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}

import {Action} from '@ngrx/store';
import {Fare} from '../../modal';

export const ADD_Fare = '[Fare] Add';
export const REMOVE_Fare = '[Fare] Remove';
export const UPDATE_Fare = '[Fare] Update';
export const POST_Fare = '[Fare] Load Request';
export const POST_Fare_SUCCESS = '[Fare] Update Success';
export const ERROR = '[Fare] Update Error';

export class AddFare implements Action {
  readonly type = ADD_Fare;
  constructor(
    public payload: Fare
  ) {}
}
export class RemoveFare implements Action {
  readonly type = REMOVE_Fare;
  constructor(
    
  ) {}
}
export class UpdateFare implements Action {
  readonly type = UPDATE_Fare;
  constructor(
    public payload: Fare
  ) {}
}

export class PostFareRequest implements Action {
  readonly type = POST_Fare;
  constructor(
    public payload: Fare[]
  ) {}
}

export class PostFareSuccess implements Action {
  readonly type = POST_Fare_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: Fare
  ) {}
}

export type Actions = AddFare | RemoveFare | UpdateFare | PostFareRequest | PostFareSuccess | Error;

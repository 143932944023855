import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PnrReducer } from './reducer';
import { PnrEffects } from './effect';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('Pnr', PnrReducer),
    EffectsModule.forFeature([PnrEffects])
  ],
  exports: [StoreModule, EffectsModule],
  providers: []
})
export class PnrStoreModule {}

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { Club } from '../../modal';

export interface ClubState extends EntityState<Club> {
    isLoading: boolean;
    selectedClubId: any;
    error: any;
}

export const ClubAdapter: EntityAdapter<Club> = createEntityAdapter<Club>({
  selectId: (editorder: Club) => 'data',
  sortComparer: false,
});

export const orderInitialState: ClubState = ClubAdapter.getInitialState({
  isLoading: true,
  selectedClubId: null,
  error: null
});

export const selectedClubId = (state: ClubState) => state.entities;
export const selectIsLoading = (state: ClubState) => state.isLoading;
export const selectError = (state: ClubState) => state.error;

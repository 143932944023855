import { Component, OnInit } from '@angular/core';
import { environment } from '../../../environments/environment';

var FootName = environment.title

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  anio: number = new Date().getFullYear();
  titleFooter: string;
  constructor() { }
    ngOnInit() {
      console.log("check footname :::",FootName);
        this.titleFooter = FootName
  
  }
  
}

import { Component, ElementRef, EventEmitter, OnInit, Output } from '@angular/core';
import {  SharedserviceService } from 'src/app/services';
import { Title } from '@angular/platform-browser';

import { NgxUiLoaderService } from 'ngx-ui-loader';
import { DateAdapter } from '@angular/material';
import { environment } from "../environments/environment";
import { fromEvent } from 'rxjs';
import { Router, NavigationEnd } from '@angular/router';
import { filter, map } from 'rxjs/operators';

// import { getMessaging, getToken, onMessage } from "firebase/messaging";
@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
  providers: [NgxUiLoaderService],
})
export class AppComponent {
  title = 'Supplier';
  message:any = null;
  browser:any;
  wesiteThemeData: any;
  faviconurl: any;
  domainName: any;
  supplierTitle: any;
  xkey:any= '';

constructor(
  private titleService: Title, private router: Router,
  public ngxloader: NgxUiLoaderService,
  public sharedService: SharedserviceService,private el: ElementRef){
}
  ngOnInit():void {
    this.browser=this.myBrowser();

    if(localStorage.getItem("web_setting_data")!= "" && localStorage.getItem("web_setting_data") != undefined && localStorage.getItem("web_setting_data") !=''){
      var JstonData:any = localStorage.getItem("web_setting_data");
      this.wesiteThemeData = JSON.parse(JstonData);
      this.supplierTitle = this.wesiteThemeData.site_title;
      this.sharedService.updateFavicon(this.wesiteThemeData.favicon_url);
    }else{
      this.getWebsiteThemeData();
    }

    this.titleService.setTitle(this.supplierTitle);
    this.setTitle(this.supplierTitle);
    this.router.events.pipe(
      filter(event => event instanceof NavigationEnd),
      map(() => {
        const child = this.router.routerState.root.firstChild;
        if (child && child.snapshot.data['title']) {
          return child.snapshot.data['title'];
        }
        return this.supplierTitle; // Fallback title
      })
    ).subscribe((title: string) => {
      this.titleService.setTitle(title);
    });
  }

  public setTitle(newTitle: string) {
    this.titleService.setTitle(newTitle);
  }

  myBrowser() {
    if((navigator.userAgent.indexOf("Opera") || navigator.userAgent.indexOf('OPR')) != -1 ) {
        return 'Opera';
    }else if(navigator.userAgent.indexOf("Chrome") != -1 ){
        return 'Chrome';
    }else if(navigator.userAgent.indexOf("Safari") != -1){
        return 'Safari';
    }else if(navigator.userAgent.indexOf("Firefox") != -1 ) {
         return 'Firefox';
    }else if(navigator.userAgent.indexOf("MSIE") != -1 ) {
      return 'IE';
    } else {
       return 'unknown';
    }
  }


  getWebsiteThemeData() {
    this.domainName = window.location.hostname;
    var requestdata = {
      domain: this.domainName,
      // domain: "supplier.flightapi.co.in",
    }
    this.sharedService.post('v1/domain_check', requestdata).subscribe((res) => {
      if (res.replyCode == 'success') {
        if ((res.data).length != 0) {
          localStorage.setItem("web_setting_data", JSON.stringify(res.data[0]));
          this.faviconurl = res.data[0].favicon_url;
          this.xkey =  res.data[0].x_api_key;
          this.sharedService.xkey = res.data[0].x_api_key;
          // this.ngxloader.stop();
          // this.colortheme = res.data[0].bg_color;

          // document.documentElement.style.setProperty(
          //   "--theme-color",
          //   res.data[0].bg_color
          // );
        }
      } else {
        // this.toastr.errorToastr(res.replyMsg, "Error");
      }
    }, error => {
      // this.toastr.errorToastr(error.error.replyMsg, "Error");
    });
  }

  requestPermission() {

    // const messaging = getMessaging();

    // getToken(messaging, { vapidKey: environment.firebase.vapidKey }).then((currentToken) => {
    //   if (currentToken) {
    //     console.log("Hurraaa!!! we got the token.....")
    //     console.log(currentToken);
    //     // Send the token to your server and update the UI if necessary
    //     // ...
    //   } else {
    //     // Show permission request UI
    //     console.log('No registration token available. Request permission to generate one.');
    //     // ...
    //   }
    // }).catch((err) => {
    //   console.log('An error occurred while retrieving token. ', err);
    //   // ...
    // });

  }
  listen() {
    // const messaging = getMessaging();
    // onMessage(messaging, (payload) => {
    //   console.log('Message received. ', payload);
    //   this.message=payload;

    // });
  }
  scroll$ = fromEvent(this.el.nativeElement,'scroll')
}

import { Injectable } from '@angular/core';
import { Actions, Effect, ofType } from '@ngrx/effects';
import { of } from 'rxjs';
import { map, switchMap, catchError, mergeMap } from 'rxjs/operators';
import { RootStoreState } from '..';
import { Store } from '@ngrx/store';
import { ReStopList } from '../../modal';

import * as ActionReStopList from './action';

@Injectable()
export class ReStopListEffects {
  constructor(private store: Store<RootStoreState.State>, private actions$: Actions) {}

  @Effect()
  loadAction$ = this.actions$
    .pipe(
      ofType<ActionReStopList.PostReStopListRequest>(ActionReStopList.POST_ReStopList),
      map(action => {return action.payload}),
      
    );
  private handleError(error) {
    return of(new ActionReStopList.Error(error));
  }
}

import { Injectable } from '@angular/core';
import * as FileSaver from 'file-saver';
import * as XLSX from 'xlsx';
import { formatDate } from '@angular/common';

const EXCEL_TYPE = 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet;charset=UTF-8';
const EXCEL_EXTENSION = '.xlsx';

@Injectable({
	providedIn: 'root'
  })
export class ExcelService {
  constructor() { }
  public exportAsExcelFile(json: any[], excelFileName: string): void {
    const worksheet: XLSX.WorkSheet = XLSX.utils.json_to_sheet(json);
    const workbook: XLSX.WorkBook = { Sheets: { 'data': worksheet }, SheetNames: ['data'] };
    const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'array' });
    //const excelBuffer: any = XLSX.write(workbook, { bookType: 'xlsx', type: 'buffer' });
    this.saveAsExcelFile(excelBuffer, excelFileName);
  }
  private saveAsExcelFile(buffer: any, fileName: string): void {
    const data: Blob = new Blob([buffer], {
      type: EXCEL_TYPE
    });
    FileSaver.saveAs(data, fileName + '_sample_' + new Date().getTime() + EXCEL_EXTENSION);
  }


  exportToDatewaiseExcel(json: any[]): void {
    const fileName = 'Datewise_Supplier_Inventory' + new Date().getTime() + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var groupByName = {};
    json.forEach(function (a) {
      groupByName[a.name] = groupByName[a.name] || [];
      groupByName[a.name].push({
        'Airline': a['airline_name'],
        'Sector': a['dep_city_code'] +'-'+ a['arr_city_code'],
        'Airline No': a['flight_number'],
        'Return Airline No': a['re_flight_number'],
        'Departure Date': formatDate(new Date(a['departure_date']), 'dd-MM-yyyy', 'en'),
        'Return Date': a['return_date'] != null ? formatDate(new Date(a['return_date']), 'dd-MM-yyyy', 'en'):" ",
        'Departure Time': a['departure_time'],
        'Arrival Time': a['arrival_time'],
        'PNR': a['pnr_number'],
        'Available': a['available_seats'],
        'Blocked': a['blocked_seats'],
        'Price': a['price'],
        'Status': a['status'] == 0 ? 'ON' : 'OFF',
      });
    });
    for (let entry in groupByName) {
      var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
      XLSX.utils.book_append_sheet(wb, wsheet, entry);
    }
    XLSX.writeFile(wb, fileName);
  }

  exportToDatewaiseBulkExcel(json: any[]): void {
    const fileName = 'Datewise_Supplier_Bulk_Inventory' + new Date().getTime() + '.xlsx';
    const wb: XLSX.WorkBook = XLSX.utils.book_new();
    var groupByName = {};
    json.forEach(function (a) {
      groupByName[a.name] = groupByName[a.name] || [];
      groupByName[a.name].push({
        'Airline': a['airline_name'],
        'Sector': a['dep_city_code'] +'-'+ a['arr_city_code'],
        'Airline No': a['flight_number'],
        'Return Airline No': a['re_flight_number'],
        'Departure Date': formatDate(new Date(a['departure_date']), 'dd-MM-yyyy', 'en'),
        'Return Date': a['return_date'] != null ? formatDate(new Date(a['return_date']), 'dd-MM-yyyy', 'en'):" ",
        'Departure Time': a['departure_time'],
        'Arrival Time': a['arrival_time'],
        'PNR': a['pnr'],
        'Available': a['bal_seat'],
        'Blocked': a['blocked_seats'],
        'Price': a['price'],
        'Status': a['status'] == 1 ? 'ON' : 'OFF',
      });
    });

    for (let entry in groupByName) {
      var wsheet = XLSX.utils.json_to_sheet(groupByName[entry]);
      XLSX.utils.book_append_sheet(wb, wsheet, entry);
    }
    XLSX.writeFile(wb, fileName);
  }

}

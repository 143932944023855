import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-header',
  templateUrl: './header.component.html',
  styleUrls: ['./header.component.scss']
})
export class HeaderComponent implements OnInit {
  showLeftBar: boolean = false;
  user_id: any;
  role_id: any;
  user: any;

  constructor(private router: Router) {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user != null) {
      this.user_id = this.user.id;
      this.role_id = this.user.role_id;
    }
  }


  ngOnInit() {
  }
  logout() {
    localStorage.removeItem("token");
    localStorage.removeItem("user");
    this.router.navigateByUrl('/')
  }

}

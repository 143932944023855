import {Action} from '@ngrx/store';
import {Pnr} from '../../modal';

export const ADD_Pnr = '[Pnr] Add';
export const REMOVE_Pnr = '[Pnr] Remove';
export const UPDATE_Pnr = '[Pnr] Update';
export const POST_Pnr = '[Pnr] Load Request';
export const POST_Pnr_SUCCESS = '[Pnr] Update Success';
export const ERROR = '[Pnr] Update Error';

export class AddPnr implements Action {
  readonly type = ADD_Pnr;
  constructor(
    public payload: Pnr
  ) {}
}
export class RemovePnr implements Action {
  readonly type = REMOVE_Pnr;
  constructor(
    
  ) {}
}
export class UpdatePnr implements Action {
  readonly type = UPDATE_Pnr;
  constructor(
    public payload: Pnr
  ) {}
}

export class PostPnrRequest implements Action {
  readonly type = POST_Pnr;
  constructor(
    public payload: Pnr[]
  ) {}
}

export class PostPnrSuccess implements Action {
  readonly type = POST_Pnr_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: Pnr
  ) {}
}

export type Actions = AddPnr | RemovePnr | UpdatePnr | PostPnrRequest | PostPnrSuccess | Error;

import { Component, OnInit } from '@angular/core';
import { Router, NavigationEnd } from '@angular/router';
import { FindValueSubscriber } from 'rxjs/internal/operators/find';
import { CommonService } from '../../services';

@Component({
  selector: 'app-layoutmaster',
  templateUrl: './layoutmaster.component.html',
  styleUrls: ['./layoutmaster.component.scss']
})
export class LayoutmasterComponent implements OnInit {

  user: any;
  user_type: any;
  name: any;
  id: any;
  showlogin: boolean = true;
  parseuser: any;
  userRef1: boolean = false;
  constructor(private router: Router, public commonService: CommonService) {
  }

  ngOnInit() {
    this.user = JSON.parse(localStorage.getItem("user"));
    if (this.user) {
      this.id = this.user.id;
      this.user_type = this.user.role_id;
      this.showlogin = false;
      this.name = this.user.name;
    }
    this.router.events.subscribe((evt) => {
      if (!(evt instanceof NavigationEnd)) {
        return;
      }
      window.scrollTo(0, 0)
    });

  }


  profile(id) {
    this.router.navigateByUrl("/dashboard/viewprofile");

  }
  userRef() {

  }
  logout() {
    this.showlogin = true;
    this.commonService.onLogout();
    if (this.userRef1 == true) {
      this.userRef1 = false;
    } else {
      this.userRef1 = true;
    }
  }

}


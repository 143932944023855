import {Action} from '@ngrx/store';
import {ReStopList} from '../../modal';

export const ADD_ReStopList = '[ReStopList] Add';
export const REMOVE_ReStopList = '[ReStopList] Remove';
export const UPDATE_ReStopList = '[ReStopList] Update';
export const POST_ReStopList = '[ReStopList] Load Request';
export const POST_ReStopList_SUCCESS = '[ReStopList] Update Success';
export const ERROR = '[ReStopList] Update Error';

export class AddReStopList implements Action {
  readonly type = ADD_ReStopList;
  constructor(
    public payload: ReStopList
  ) {}
}
export class RemoveReStopList implements Action {
  readonly type = REMOVE_ReStopList;
  constructor(
    
  ) {}
}
export class UpdateReStopList implements Action {
  readonly type = UPDATE_ReStopList;
  constructor(
    public payload: ReStopList
  ) {}
}

export class PostReStopListRequest implements Action {
  readonly type = POST_ReStopList;
  constructor(
    public payload: ReStopList[]
  ) {}
}

export class PostReStopListSuccess implements Action {
  readonly type = POST_ReStopList_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: ReStopList
  ) {}
}

export type Actions = AddReStopList | RemoveReStopList | UpdateReStopList | PostReStopListRequest | PostReStopListSuccess | Error;

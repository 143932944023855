import {Action} from '@ngrx/store';
import {PlanList} from '../../modal';

export const ADD_PlanList = '[PlanList] Add';
export const REMOVE_PlanList = '[PlanList] Remove';
export const UPDATE_PlanList = '[PlanList] Update';
export const POST_PlanList = '[PlanList] Load Request';
export const POST_PlanList_SUCCESS = '[PlanList] Update Success';
export const ERROR = '[PlanList] Update Error';

export class AddPlanList implements Action {
  readonly type = ADD_PlanList;
  constructor(
    public payload: PlanList
  ) {}
}
export class RemovePlanList implements Action {
  readonly type = REMOVE_PlanList;
  constructor(
    
  ) {}
}
export class UpdatePlanList implements Action {
  readonly type = UPDATE_PlanList;
  constructor(
    public payload: PlanList
  ) {}
}

export class PostPlanListRequest implements Action {
  readonly type = POST_PlanList;
  constructor(
    public payload: PlanList[]
  ) {}
}

export class PostPlanListSuccess implements Action {
  readonly type = POST_PlanList_SUCCESS;
  constructor(
    public payload: []
  ) {}
}

export class Error implements Action {
  readonly type = ERROR;
  constructor(
    public payload: PlanList
  ) {}
}

export type Actions = AddPlanList | RemovePlanList | UpdatePlanList | PostPlanListRequest | PostPlanListSuccess | Error;

import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {
  settingdata: any;
  colortheme: any;
  footer_copywrite: any;
  user: any;
  user_id: any;

  constructor() {
    this.user = JSON.parse(localStorage.getItem("user"));

    if (this.user != null) {
      this.user_id = this.user.id;
    }

    setTimeout(() => {
      if(JSON.parse(localStorage.getItem("web_setting_data"))!= "" && JSON.parse(localStorage.getItem("web_setting_data")) != undefined){
        this.settingdata = JSON.parse(localStorage.getItem("web_setting_data"));
        document.documentElement.style.setProperty(
          "--theme-color",
          this.settingdata.bg_color
          );
          this.colortheme = this.settingdata.bg_color;
          this.footer_copywrite = this.settingdata.footer_copywrite;
        }else{
          // this.getWebsiteThemeData();
        }
      }, 400);
  }

  ngOnInit() {

  }

}

import * as ActionsPnr from './action';
import * as StatePnr from './state';
import * as PnrEffects from './effect';

export { PnrStoreModule } from './pnr-store.module';

export {
  ActionsPnr,
  StatePnr,
  PnrEffects
};

import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { StoreModule } from '@ngrx/store';
import { EffectsModule } from '@ngrx/effects';
import { PlanListReducer } from './reducer';
import { PlanListEffects } from './effect';
@NgModule({
  imports: [
    CommonModule,
    StoreModule.forFeature('PlanList', PlanListReducer),
    EffectsModule.forFeature([PlanListEffects])
  ],
  exports: [StoreModule, EffectsModule],
  providers: []
})
export class PlanListStoreModule {}

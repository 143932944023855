import { Select2OptionData } from 'ng-select2';
import { Options } from 'select2';
import { AfterViewInit, Component, OnDestroy, OnInit } from '@angular/core';
import { HostListener, Output, EventEmitter, ViewChild, ElementRef, ChangeDetectorRef } from '@angular/core';
import { FormGroup, FormBuilder, Validators, FormControl, ValidationErrors } from '@angular/forms';
import { Router, ActivatedRoute } from '@angular/router';
import { MustMatch } from 'src/app/services/must-match';
import { SharedserviceService } from 'src/app/services/sharedservice.service';
import { CommonService } from 'src/app/services/commonservice.service'
import { DatePipe } from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { from } from 'rxjs';
import { ToastrManager } from 'ng6-toastr-notifications';

import { MatSelect } from '@angular/material/select';
import { ReplaySubject, Subject } from 'rxjs';
import { take, takeUntil } from 'rxjs/operators';
import { DateAdapter } from '@angular/material';
import { NgxUiLoaderService } from 'ngx-ui-loader';
declare var jQuery: any;
declare var $: any;

@Component({
  selector: 'app-dashboard',  templateUrl: './dashboard.component.html',  styleUrls: ['./dashboard.component.scss'],
  providers: [DatePipe, NgxUiLoaderService]
})
export class DashboardComponent implements OnInit, AfterViewInit, OnDestroy {
  // airline;
  // destination;
  // stop;
  Timings:any=[
    { val: '1', name: "Before 6 Am", is_selected: false }, 
    { val: '2', name: "6 Am - 12 Pm", is_selected: false }, 
    { val: '3', name: "12 Pm - 6 Pm ", is_selected: false },
    { val: '4', name: "After 6 Pm", is_selected: false }
  ]
  InTimings:any=[
    { val: '1', name: "Before 6 Am", is_selected: false }, 
    { val: '2', name: "6 Am - 12 Pm", is_selected: false }, 
    { val: '3', name: "12 Pm - 6 Pm ", is_selected: false },
    { val: '4', name: "After 6 Pm", is_selected: false }
  ]
  partnerSearch: string = 'normal';  Airlines: any = [];  Destinations: any = [];
  Stops: any = [
    { val: '0', name: "Default", is_selected: false }, { val: '1', name: "1 Stop", is_selected: false }, { val: '2', name: "2 Stop", is_selected: false }
  ]; 
  Refundable={ val: "REFUNDABLE", name: "Refundable Fare", is_selected: false }
  
  Regularsearch:any=false;   Regular_search_depcode:any;Regular_search_arrcode:any; filterprice:any=0;
  public options: Options;  flightdetails_old: any; disableDate: boolean = true; todayDate:any=new Date();
  flightdetails: any;  getData: any;
  departurePartnerList: any;  arrivalPartnerList: any;
  departure_city_data: Array<Select2OptionData>;
  public options1: Options;  
  arrival_city_data: Array<Select2OptionData>;
  rreturn_d_date: any;  return_d_date: any;
  airport_city_data: any;  exampleData: any;
  public submitted = false;  dropCity: boolean = true;
  searchForm: FormGroup;  travellers: FormGroup;
  total: any;  flight: any; date: any;
  type: any;  selecttype: any = 'Select By Airline';  disableInput: boolean;
  return_date: string;  searchnotresult: boolean = false;  newCal: any;  newCal2: any;  newCal3: any;
  search_details: any;  imagesrc: any;  searchresult: boolean = false;
  searchdate: any = '';  aval_seat: any;
  showoption:boolean=false;arrcity_id:any='';
  showoption1:boolean=false;

  previous_day: any;  to_day: any;  next_day: any;  readMore: boolean = true;
  minDate: any;  maxDate: any;  return_min_date: any;  return_max_date: any;

  arrivaldate_input: boolean = false;  departure_date: boolean = false;  return_date_input: boolean = false;
  traveller_input: boolean = false;  departure_city_lists: any;  arrival_city_lists: any;
  departure_city_ids: any;  arrival_city_ids: any;  d_date: any;
  flighttype: any = 'one_way';  validDates: any;  myFilter: any;
  myFilter1: any;  partner_dep_id: any;  d_city: any = [];  a_city: any = [];

  /** list of departure cities */
  Departurecity_DATA: departurecity_lists[] = [];
  protected dcitylist: departurecity_lists[];  public departureCtrl: FormControl = new FormControl();
  /** control for the MatSelect filter keyword */
  public departureFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filtered_departurecity: ReplaySubject<departurecity_lists[]> = new ReplaySubject<departurecity_lists[]>(1);
  @ViewChild('departureSelect', { static: true }) departureSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy = new Subject<void>();

  isSticky: boolean = false; departurecitycode:any='';

  /** list of arrival cities */
  Arrivalcity_DATA: arrivalcity_lists[] = [];
  protected acitylist: arrivalcity_lists[];
  /** control for the MatSelect filter keyword */
  public arrivalFilterCtrl: FormControl = new FormControl();
  /** list of banks filtered by search keyword */
  public filtered_arrivalcity: ReplaySubject<arrivalcity_lists[]> = new ReplaySubject<arrivalcity_lists[]>(1);
  @ViewChild('arrivalSelect', { static: true }) arrivalSelect: MatSelect;
  /** Subject that emits when the component has been destroyed. */
  protected _onDestroy1 = new Subject<void>();
  UserData: any;
  fitSearchCounter: any;

  constructor(
    private ngxLoader: NgxUiLoaderService, 
    private dateAdapter: DateAdapter<Date>, 
    private datePipe: DatePipe, 
    private activatedRoute: ActivatedRoute, 
    private fb: FormBuilder, 
    public dataservice: CommonService, 
    public httpClient: SharedserviceService, 
    public router: Router, 
    private toastr: ToastrManager) {
    this.dateAdapter.setLocale('en-GB');
    this.UserData = JSON.parse(this.dataservice.getUser());
    httpClient.auth_token = this.UserData.login_token
  }

  chngoption(){
    if(this.showoption == false ){
      this.showoption=true;
    }else{
      this.showoption=false;
    }
  }

  chngoption1(){
    if(this.showoption1 == false ){
      this.showoption1=true;
    }else{
      this.showoption1=false;
    }
  }


  initiate() {
    this.searchForm = this.fb.group({
      departure_city_id: ['', [Validators.required]],  departure_city_code: ['', [Validators.required]],  departure_airport_id: ['', [Validators.required]],
      arrival_city_id: ['', [Validators.required]],  arrival_city_code: ['', [Validators.required]],  arrival_airport_id: ['', [Validators.required]],
      trip_type: ['0', [Validators.required]],  departure_date: ['', [Validators.required]],  return_date: [''],
      adult: 1,  child: 0,  travellers: '1',  infant: 0,saerchdep:'',searcharr:''
    });
    this.travellers = this.fb.group({  adult: ['1'],  child: ['0'],  infant: ['0'],  });
    $('mat-select-search-input').attr('placeholder', 'departure city')
  }

  ngOnInit() {
    this.initiate();  this.departure_city_list()
    this.disableInput = true;
    window.addEventListener('scroll', this.scroll, true);  
    if (this.readMore == true) {  this.readMore != this.readMore;  }
    // setTimeout(() => {  console.clear(); }, 100);
  }

  changetoRegular(e) {
    if (e.target.checked) {  this.Regularsearch = true;  } 
    else {  
      this.Regularsearch = false;
      this.showoption=false;
      this.showoption1=false;
    }
    this.flightdetails=null; this.departure_city_data=null; this.arrival_city_data=null; this.minDate=new Date(); this.maxDate=null; this.searchresult=false;
    this.initiate();  this.departure_city_list();
  }

  contactSupport() {
    const self = this;
    self.httpClient.get('v1/users/get_website_owner_data').subscribe((res: any) => {
      if (res.replyCode == "success") {  this.getData = res.data;  $('#offlineModle').modal('show');  } 
      else {  }
    });
  }



  scroll = (event): void => {
    this.isSticky = window.pageYOffset >= 100;
  };

  timeConvert(n) {
    var num = n;  var hours = (num / 60);
    var rhours = Math.floor(hours);  var minutes = (hours - rhours) * 60;  var rminutes = Math.round(minutes);
    return rhours + " hr  " + rminutes + " min";
  }

  durationdiff(dep_time, arr_time, dep_date, type) {
    // console.log("dep_time, arr_time, dep_date, type",dep_time, arr_time, dep_date, type)
    var dep_hr = parseInt(dep_time.split(':')[0]);  var arr_hr = parseInt(arr_time.split(':')[0]);
    if (arr_hr < dep_hr) {
      var nwdate;
      if (!dep_date) {  nwdate = new Date();  } 
      else {  nwdate = new Date(dep_date);  }
      var newdate = nwdate.setDate(nwdate.getDate() + 1);
      if (type == 'oneway') {  this.return_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');  } 
      else {  this.rreturn_d_date = this.datePipe.transform(newdate, 'yyyy-MM-dd');  }
    } 
    else {  this.return_d_date = dep_date;  this.rreturn_d_date = dep_date;  }
    var start_date = dep_date + " " + dep_time;  var end_date = this.return_d_date + " " + arr_time;
    return this.getDataDiff(new Date(start_date.toString()), new Date(end_date.toString()));
  }

  getDataDiff(s_date, e_date) {
    var diff = e_date.getTime() - s_date.getTime();
    var days = Math.floor(diff / (60 * 60 * 24 * 1000));  var hours = Math.floor(diff / (60 * 60 * 1000)) - (days * 24);
    var minutes = Math.floor(diff / (60 * 1000)) - ((days * 24 * 60) + (hours * 60));
    var seconds = Math.floor(diff / 1000) - ((days * 24 * 60 * 60) + (hours * 60 * 60) + (minutes * 60));
    return hours + " hrs " + ": " + minutes + " min";
  }
  checked = false;

  duration(duration) {
    console.log("duration value:::::::::151::::::",duration);    
    var duration_split = duration.split(':');
    return duration_split[0] + ' hrs : ' + duration_split[1] + ' mins';
  }

  renderdata() {
    this.search_details = this.searchForm.value;
    setTimeout(() => {
      this.flightdetails.forEach(element => {
        var obj = element;
        // if (element.inventory_user_Type == 'Patner') {
        //   element.total_payable_price_with_travellers = element.total_payable_price * this.search_details.travellers
        // }
        if (element.inventory_user_Type == 'fareboutique' && element.add_margin == 1) {
          this.newCal = element.creditlmitAmount < element.total_payable_price;
          this.newCal2 = element.creditlmitAmount > element.per_ticket_avg_price + element.margin - element.discount;
          this.newCal3 = ~~(element.creditlmitAmount / (element.per_ticket_avg_price + element.margin - element.discount));
          // element.total_payable_price = element.total_payable_price + ((element.margin - element.discount) * this.search_details.travellers)
          Object.assign(obj, { newCal: this.newCal, newCal2: this.newCal2, newCal3: this.newCal3 })
        }
      });
    }, 100);
    setTimeout(() => {
      if (this.search_details.trip_type == '1') {  this.flighttype == 'return'  } 
      else {  this.flighttype == 'one_way';  this.disableInput = true;  this.return_date_input = false;  }
    }, 1000);
  }

  SearchCity(){
    const self = this;   this.departure_city_data = [];    this.d_city = [];
    self.httpClient.loadingShow = true;
    var searchdep=this.searchForm.value.saerchdep;
    console.log("seachcitydep",searchdep)
    var sendrequestdata = { city_name:searchdep,  /*// flight_type: this.flighttype  // flight_type:"one_way" */  }
    this.httpClient.post('v1/city/list', sendrequestdata).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res.replyCode == "success") {
        this.departure_city_lists = res.data;
        res.data.forEach(element => {
          this.d_city.push({
            id: element.city_id,  text: element.city_name,
            depcode: element.city_code,  searchtype: 'normal',
            additional: {   image: element.airport_code,   winner: element.airport_name,    airportId: element.airport_id }
          })
        });
        this.departure_city_data = this.d_city;
        this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
      }
    });
  }
  
  SearchArrCity(){
    const self = this;   this.arrival_city_data = [];    this.a_city = [];
    self.httpClient.loadingShow = true;
    console.log("form output :::::",this.searchForm.value)
    var searcharr=this.searchForm.value.searcharr;
    console.log("seachcitydep", searcharr)
      var sendrequestdata = { city_name:searcharr,  /*// flight_type: this.flighttype  // flight_type:"one_way" */  }
      this.httpClient.post('v1/city/list', sendrequestdata).subscribe((res) => {
        self.httpClient.loadingShow = false;
        if (res.replyCode == "success") {
          this.arrival_city_lists = res.data;
          res.data.forEach(element => {
            this.a_city.push({
              id: element.city_id,  
              text: element.city_name,
              depcode: element.city_code,  
              searchtype: 'normal',
              additional: {   
                image: element.airport_code,   
                winner: element.airport_name,    
                airportId: element.airport_id }
            })
          });
          this.arrival_city_data = this.a_city;
          this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
        }
      });
  }

  setsearchdate(d) {
    this.searchForm.patchValue({  departure_date: this.datePipe.transform(d, "yyyy-MM-dd")  });
    this.searchForm.value.departure_date = this.datePipe.transform(d, "yyyy-MM-dd");
    this.searchdate = this.datePipe.transform(d, "yyyy-MM-dd")
    this.searchsubmit();
  }


  filter() {
    this.flightdetails = this.flightdetails_old;   var pricefilter=[]; var Refundfilter=[];  var TempArr=[]
    var airlinefilter = [];  var destinationfilter = [];  var stopsfilter = []; var timingfilter=[]; var Intimingfilter=[]; var merged_array = [];
    var tempdata=this.flightdetails_old;
    
/* Timing  Filter*/    
    if(this.Timings){
      this.Timings.forEach(element => {  if (element.is_selected == true) {  timingfilter.push(element.val);  }  });
    }
/* Timing Filter*/

/* InTiming Filter*/    
    if(this.InTimings){
      this.InTimings.forEach(element => {  if (element.is_selected == true) {  Intimingfilter.push(element.val);  }  });
    }
/* InTiming Filter*/

/* Airline Filter*/    
    if (this.Airlines) {
      this.Airlines.forEach(element => {  if (element.is_selected == true) {  airlinefilter.push(element.name);  }  });
    }
/* Airline Filter*/

/* Destination Filter*/
    // if (this.Destinations) {
    //   this.Destinations.forEach(element => {
    //     if (element.is_selected == true) {
    //       var temp = this.flightdetails.filter(item => item.arrival_city_name === element.name)
    //       if (temp.length > 1) {  temp.forEach(element => {  destinationfilter.push(element);  });  } 
    //       else if (temp.length == 1) {  destinationfilter.push(temp[0]);  }
    //     }
    //   });
    // }
/* Destination Filter*/    

/* Stop Filter*/
    var stopdata = 0;
    if (this.Stops) {
      this.Stops.forEach(element => {  if (element.is_selected == true) {  stopsfilter.push(element.val);  }  });
    }
/* Stop Filter*/    

    this.flightdetails=tempdata.filter(item => {
      return this.isinPriceRange(item) && 
             (airlinefilter.some(b => b === item.airline_name) || airlinefilter.length === 0) && 
             (timingfilter.some(b => b === item.dep_day) || timingfilter.length === 0) && 
             (Intimingfilter.some(b => b === item.arr_day) || Intimingfilter.length === 0) && 
             (stopsfilter.some(b => b === item.no_of_stop) || stopsfilter.length === 0)
    });
  console.log("flightdetails ::::: >>>>>",this.flightdetails)  
  }

  isinPriceRange(item){
    if(this.filterprice > 0){
      if(item.total_payable_price <= this.filterprice){
        return true;
      }else{
        return false;
      }
    }else{
      return true;
    }
  }


  showcompany(data) {
    localStorage.setItem("companyname", data);
    // this.dataservice.companyname=data;
  }

  public templateResult = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {  return state.text;  }
    return jQuery('<span style="font-weight:500">' + state.text + ' </span><p style="margin:0px;font-size:10px;position:relative"><span style="font-size:12px;float:right;right:3px;margin-top:-20px;font-weight:500;position:absolute;">' + state.additional.image + '</span></p>');
  }
  public templateSelection = (state: Select2OptionData): JQuery | string => {
    if (!state.id) {  return state.text;  }
    return jQuery('<span>' + state.text + '&nbsp; (' + state.additional.image + ')</span>');
  }

  searchcity(){
  }
 
/* // only common api*/
  departure_city_list() {
    var sendrequestdata:any;
    if(this.Regularsearch == false){
      this.ngxLoader.start();  const self = this;  self.httpClient.loadingShow = true;
      sendrequestdata = { flight_type: this.flighttype }
      this.departure_city_data = [];  this.partnerSearch = '';  this.d_city = [];
      this.httpClient.post('v1/common/dep_city',sendrequestdata).subscribe((res) => {
        self.httpClient.loadingShow = false;
        if (res.replyCode == "success") {
          if (res.data.length != 0) {
            this.departurePartnerList = res.data;
            self.departurePartnerList.forEach(element => {
                self.d_city.push({
                  id: element.departure_city_id,  
                  text: element.departure_city_name,
                  // text: element.departure_city_name+' <span style="display:none;">'+element.departure_city_code+'</span>',
                  depcode: element.departure_city_code,  searchtype: 'partner',
                  additional: { image: element.airport_code, winner: element.airport_name, airportId: element.airport_id }
                })
            });
          }
          this.d_city.sort((a, b) => a.text.localeCompare(b.text));
          this.departure_city_data = this.d_city; this.ngxLoader.stop();
        }
        this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
      }, err => {
        this.departure_city_data = this.d_city;   this.ngxLoader.stop();  this.toastr.errorToastr(err.error.message, "Error")
        this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
      }
      );
    }else{
      const self = this;   this.departure_city_data = [];    this.d_city = [];
      self.httpClient.loadingShow = true;
      sendrequestdata = { city_name:"",  /*// flight_type: this.flighttype  // flight_type:"one_way" */  }
      this.httpClient.post('v1/city/list', sendrequestdata).subscribe((res) => {
        self.httpClient.loadingShow = false;
        if (res.replyCode == "success") {
          // this.departure_city_lists = res.data;
          res.data.forEach(element => {
            this.d_city.push({
              id: element.city_id,  text: element.city_name,
              // id: element.city_id,  text: element.city_name+' <span style="display:none;">'+element.city_code+'</span>',
              depcode: element.city_code,  searchtype: 'normal',
              additional: {   image: element.airport_code,   winner: element.airport_name,    airportId: element.airport_id }
            })
          });
          this.departure_city_data = this.d_city;
          this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection, tags: true};
        }
      });
    }
  }

arrival_city_list(id) {
    console.log('arrival_city_list === id ============================== 275 ===', id);
    // if (this.arrival_city_data.length > 0) {  
      this.arrival_city_data = [];  this.a_city = []; var sendrequestdata; 
    // }
    if (id) {
      if(this.Regularsearch == false){
        this.ngxLoader.start();
        this.arrcity_id=id;
        var filterlist = this.d_city.filter(x => x.id == id);  this.partnerSearch = 'partner';
        var datalist = this.departurePartnerList.filter(x => x.departure_city_id == id);
        console.log("filterlist :::::::::::::", this.partnerSearch,"filter",filterlist,"datalist",datalist, id)
        this.departure_city_ids = filterlist[0].depcode;
        this.searchForm.patchValue({ departure_city_code: id, departure_city_id: filterlist[0].id, departure_airport_id: filterlist[0].additional.airportId })
        const self = this;  self.httpClient.loadingShow = true;
        sendrequestdata = {  departure_city_code: filterlist[0].depcode,  departure_city_id:filterlist[0].id , flight_type: this.flighttype}
        this.httpClient.post('v1/common/arr_city', sendrequestdata).subscribe((res) => {
          self.httpClient.loadingShow = false;
          if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
          if (res.replyCode == "success" && res.data.length > 0) {
            self.arrivalPartnerList = res.data;  this.Arrivalcity_DATA.length = 0;
            self.arrivalPartnerList.forEach(element => {
              this.a_city.push({
                id: element.arrival_city_id,  text: element.arrival_city_name,  
                // id: element.arrival_city_id,  text: element.arrival_city_name+' <span style="display:none;">'+element.arrival_city_code+'</span>',  
                depcode: element.arrival_city_code,
                additional: {  image: element.airport_code,  winner: element.airport_name,  airportId: element.airport_id  }
              })
            });
            this.a_city.sort((a, b) => a.text.localeCompare(b.text));  this.arrival_city_data = this.a_city;
            this.options1 = {  templateResult: this.templateResult,  templateSelection: this.templateSelection  };
          }
        });
      }else{
        this.chngoption();
        this.a_city  = this.d_city.filter(function( obj ) {  return obj.id != id;  });        
        var filterlist = this.d_city.filter(x => x.id == id);
        console.log('arrival_city_list === id ============================== 275 ===', id);
        console.log("d-city ::::::::>>>>>>>>",this.d_city,filterlist)
        this.searchForm.patchValue({ saerchdep : filterlist[0].text})
        this.Regular_search_depcode=filterlist[0].additional.image;  this.departure_city_ids = filterlist[0].id;
        this.searchForm.patchValue({departure_city_code: filterlist[0].depcode, departure_city_id: filterlist[0].id, departure_airport_id: filterlist[0].additional.airportId })
        const self = this; self.arrival_city_lists=this.a_city;  self.arrival_city_data=this.a_city; self.httpClient.loadingShow = true;
        this.options1 = {   templateResult: this.templateResult,   templateSelection: this.templateSelection };
        console.log("arrival_city_data",this.arrival_city_data)
      }
  }
}

arrival_date(id) {
    if (id) {
      if(this.Regularsearch == false ){
        var sendrequestdata;
        this.ngxLoader.start();  var validdates = [];  var datalist = this.a_city.filter(x => x.id == id);
        console.log("citylist", this.a_city, "id", id, "result ::::", datalist)
        this.arrival_city_ids = datalist.length == 0 ? '' : datalist[0].depcode;
        this.searchForm.patchValue({ arrival_city_code: id, arrival_city_id: datalist[0].id, arrival_airport_id: datalist[0].additional.airportId })
        const self = this;  self.httpClient.loadingShow = true;
        sendrequestdata = { 
          departure_city_code: this.departure_city_ids,   arrival_city_code: this.arrival_city_ids ,is_return:this.flighttype == 'one_way'?0:1,
          departure_city_id:this.searchForm.value.departure_city_id,  arrival_city_id:this.searchForm.value.arrival_city_id
        }
        self.httpClient.post('v1/common/dates', sendrequestdata).subscribe((res) => {
          self.httpClient.loadingShow = false;
          if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
          if (res.replyCode == "success") {
            self.d_date = res.data;  var date_length = res.data.length;
            if (date_length > 0) {  this.minDate = self.d_date[0].flight_date;  this.maxDate = self.d_date[date_length - 1].flight_date  }
            if (date_length == 1) {  validdates.push(new Date(this.datePipe.transform(self.d_date[0].flight_date, 'MM/dd/yyyy')))  } 
            else if (date_length > 1) {  res.data.forEach(element => {  validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))  });  }
            this.myFilter1 = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  }
            }
        });
      }else{
        
        this.chngoption1();
        if (this.myFilter1) {   this.myFilter1 = !this.myFilter1;  }
        this.searchForm.patchValue({ return_date: '' });  this.searchForm.patchValue({ departure_date: '' });
        if (id) {
          var datalist = this.a_city.filter(x => x.id == id); this.disableDate = false;
          this.arrival_city_ids = datalist.length == 0 ? '' : datalist[0].id; this.Regular_search_arrcode=datalist[0].additional.image;
          this.searchForm.patchValue({ searcharr : datalist[0].text})
          this.searchForm.patchValue({ arrival_city_code: datalist[0].depcode, arrival_city_id: datalist[0].id, arrival_airport_id: datalist[0].additional.airportId })
          // var startDate =this.datePipe.transform(new Date(),"yyyy-MM-dd"); //YYYY-MM-DD
          // var future = new Date();  future.setDate(future.getDate() + 100);  
          // var endDate = this.datePipe.transform(future,"yyyy-MM-dd"); //YYYY-MM-DD
          // console.log("100 future date :::::::::::>>>>>>>>>>",startDate,endDate)
          // var dateArr = this.getDateArray(startDate, endDate);
          // console.log(" date array ::::::::::::",dateArr)
          var result = [];
          for (var i = 0; i < 100; i++) {
              var d = new Date();  d.setDate(d.getDate() + i);  result.push( {flight_date:this.datePipe.transform(new Date(d), 'yyyy-MM-dd')})  
          }
          setTimeout(() => {
            this.d_date=result;
            console.log("date array ::::>>>>>",this.d_date)
          }, 200);
        }    
      }
    }
}

getDateArray(start, end) {
  var arr = new Array();
  var dt = new Date(start);
  while (dt <= end) {
      arr.push(new Date(dt));
      dt.setDate(dt.getDate() + 1);
  }
  return arr; 
}

  searchsubmit() {
    this.Airlines = [];  this.Destinations = [];   const self = this; this.ngxLoader.start();
    this.submitted = true;  let formdata = this.searchForm.value;

    console.log("formdata ::::::::",formdata)
    if (formdata.departure_city_code != "" && formdata.arrival_city_code != "") {
      if(this.Regularsearch == true){
        if (formdata.departure_city_id != "" && formdata.departure_airport_id != '' && formdata.arrival_city_id != "" && formdata.arrival_airport_id != '') {
          var sendrequestdata = {
            dep_city_code: this.Regular_search_depcode,  arr_city_code: this.Regular_search_arrcode,  departure_city_id: formdata.departure_city_id,  
            departure_airport_id: formdata.departure_airport_id,  arrival_city_id: formdata.arrival_city_id,
            arrival_airport_id: formdata.arrival_airport_id,  trip_type: Number(formdata.trip_type),
            departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
            return_date: formdata.return_date,  adult: formdata.adult,  children: formdata.child,  infant: formdata.infant,
            prefered_airline:''
          }
          this.to_day = this.searchForm.value.departure_date; var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);
          if (this.d_date[findindex - 1] == undefined) {  this.previous_day = '';  } 
          else {  this.previous_day = this.d_date[findindex - 1]  }
          if (this.d_date[findindex + 1] == undefined) {  this.next_day = '';  } 
          else {  this.next_day = this.d_date[findindex + 1]  }
          this.fitSearchCounter = 0;
           this.httpClient.post('v1/fit/airline_list', sendrequestdata).subscribe((res: any) => {
            if(res.replyCode == "success") {
              if(res.data.length == 0){
                sessionStorage.setItem("search_form", JSON.stringify(sendrequestdata));
                var d_city = [];  var showOnlySelf = true;
                this.fitSearchCounter += 1;
                this.httpClient.post('v1/fit/search', sendrequestdata).subscribe((res: any) => {
                  if (res.replyCode == "success") {
                    this.ngxLoader.stop();
                    // this.booking_token = res.booking_token_id;  sessionStorage.setItem("booking_token_id", JSON.stringify(this.booking_token));
                    this.imagesrc = res.length > 0 ? res.imgBasePath : '';  this.imagesrc = res.imgBasePath;  this.searchresult = true;  this.submitted = false;
                    if (res.data) {  d_city = res.data;  }
                    this.flightdetails = [];
                    d_city.forEach(val => {
                      this.fitSearchCounter -= 1;
                      if (showOnlySelf==true) {
                        if (val.inventory_user_Type == 'self') {  this.flightdetails.push(val);  } 
                        else {
                          var insertCheck = d_city.find(ele => ele.departure_date == val.departure_date && ele.inventory_user_Type == 'self')
                          if (!insertCheck) {  this.flightdetails.push(val);  }
                        }
                      } else {  this.flightdetails = d_city;  }
                      this.flightdetails_old = d_city;  this.flightdetails = this.flightdetails_old;
                      (res.data).forEach(element => {
                        var AirlineCheck = this.Airlines.find(ele => ele.name == element.airline_name)
                        if (!AirlineCheck) {  this.Airlines.push({ name: element.airline_name, is_selected: false })  }
                        var DestinationCheck = this.Destinations.find(ele => ele.name == element.arrival_city_name)
                        if (!DestinationCheck) {  this.Destinations.push({ name: element.arrival_city_name, is_selected: false })  }
                      });
                      this.flightdetails.sort(function (a, b) {
                        return a.final_pay_amount - b.final_pay_amount
                      })
                      this.flightdetails.forEach(element => { element.departure_month=element.departure_date.split('-')[1]  });
                    })
                  }
                }, err => {  this.imagesrc = '';  this.submitted = false;  this.toastr.errorToastr(err.error.replyMsg, "Error");  }
                );  
              }else{
                this.fitSearchCounter = 0;
                var d_city = [];    this.flightdetails = [];
                (res.data).forEach(element => {
                  this.fitSearchCounter += 1;
                  sendrequestdata.prefered_airline=element;
                  sessionStorage.setItem("search_form", JSON.stringify(sendrequestdata));
                  var showOnlySelf = true;
                  this.httpClient.post('v1/fit/search', sendrequestdata).subscribe((res: any) => {
                    this.fitSearchCounter -= 1;
                    if (res.replyCode == "success") {
                      this.ngxLoader.stop();
                      this.imagesrc = res.length > 0 ? res.imgBasePath : '';  this.imagesrc = res.imgBasePath;  this.searchresult = true;  this.submitted = false;
                      if (res.data) {  
                          (res.data).forEach(element => {
                            var AirlineCheck = this.Airlines.find(ele => ele.name == element.airline_name)
                            if (!AirlineCheck) {  this.Airlines.push({ name: element.airline_name, is_selected: false })  }
                            var DestinationCheck = this.Destinations.find(ele => ele.name == element.arrival_city_name)
                            if (!DestinationCheck) {  this.Destinations.push({ name: element.arrival_city_name, is_selected: false })  }
                          });
                        this.flightdetails = this.flightdetails.concat(res.data);  
                        this.flightdetails.sort(function (a, b) {
                          return a.final_pay_amount - b.final_pay_amount
                        })
                        this.flightdetails_old=this.flightdetails;
                        // console.log('d_city ======================================= 674 ===', this.flightdetails);
                      }
                    }
                  }, err => {  this.imagesrc = '';  this.submitted = false;  this.toastr.errorToastr(err.error.replyMsg, "Error");  }
                  );
                });
              }
            }  
            });    
        }
        else {
          this.toastr.errorToastr('Fill The Form Correctly.');
        }
      }else{
        var datarequest = {
          dep_city_code: this.departure_city_ids,  arr_city_code: this.arrival_city_ids,trip_type:this.flighttype == 'one_way'?0:1,
          dep_airport_id: this.searchForm.value.departure_airport_id,  arr_airport_id: this.searchForm.value.arrival_airport_id,
          departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,  return_date: formdata.return_date,  
          dep_city_id: formdata.departure_city_id,  arr_city_id: formdata.arrival_city_id,
          adult: formdata.adult,  children: formdata.child,  infant: formdata.infant
        }
        this.to_day = this.searchForm.value.departure_date; var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);
        if (this.d_date[findindex - 1] == undefined) {  this.previous_day = '';  } 
        else {  this.previous_day = this.d_date[findindex - 1]  }
        if (this.d_date[findindex + 1] == undefined) {  this.next_day = '';  } 
        else {  this.next_day = this.d_date[findindex + 1]  }
        this.httpClient.post('v1/common/search', datarequest).subscribe((res: any) => {
          if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
          if (res.replyCode == "success") {
            var sessiondata = {
              adult: formdata.adult,  arrival_city_code: this.arrival_city_ids,
              child: formdata.child,  departure_airport_id: formdata.departure_airport_id,
              departure_city_code: this.departure_city_ids,  departure_city_id: formdata.departure_city_id,
              arrival_city_id: formdata.arrival_city_id,  arrival_airport_id: formdata.arrival_airport_id,
              departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
              infant: formdata.infant,  return_date: formdata.return_date,
              travellers: formdata.travellers,  trip_type: formdata.trip_type
            }
            sessionStorage.setItem("search_form", JSON.stringify(sessiondata));
            if (res.data.length == 1) {  (res.data[0]).partnervalue = 'patner'  } 
            else {  (res.data).forEach(element => {  element.partnervalue = 'patner'  });  }
            sessionStorage.setItem("Booking_token", JSON.stringify(res.booking_token));
            this.flightdetails_old = res.data;  this.flightdetails = this.flightdetails_old;
            (res.data).forEach(element => {
              this.Airlines.push({ name: element.airline_name, is_selected: false })
              this.Destinations.push({ name: element.arrival_city_name, is_selected: false })
            });
            this.imagesrc = res.imgBasePath;  this.searchresult = true;  this.renderdata()
          } else {
            this.flightdetails = '';  this.imagesrc = ''; this.ngxLoader.stop();
            sessionStorage.removeItem("flights");  sessionStorage.removeItem("imagepath");  self.toastr.errorToastr("Result Not Found", "Error")
          }
        }, err => {
          this.flightdetails = '';  this.imagesrc = '';  this.toastr.errorToastr(err.error.replyMsg, "Error");  this.ngxLoader.stop();
        });
      }

    }
    else {  this.toastr.errorToastr('Fill The Form Correctly.');  this.ngxLoader.stop();  }

}

  // bookflight(flight) {
  //   const self = this;  var formvalue = this.searchForm.value
  //   this.aval_seat = flight.total_available_seats;  var totalseat = Number(formvalue.adult) + Number(formvalue.child)
  //   if (this.aval_seat >= totalseat) {
  //     var senddata = {
  //       flight_id: flight.flight_id,  trip_type: formvalue.trip_type,
  //       departure_date: formvalue.departure_date,  return_date: formvalue.return_date,
  //       adult: formvalue.adult,  children: formvalue.child,  infant: formvalue.infant,  live_pnr: flight.live_pnr,  inventory_user_Type: "self"
  //     }
  //     sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
  //     sessionStorage.setItem("partnerSearch", JSON.stringify(true));  self.router.navigateByUrl("detail"); 
  //   } else {  $('#confirmbooking').modal('show');  }
  // }

  bookflight(flight) {
    console.log("flight ::::::::::::>>>>",flight)
    sessionStorage.setItem("book_own_inv",JSON.stringify(false));
    const self = this;
    var formvalue = this.searchForm.value;  sessionStorage.setItem("Regular Search",JSON.stringify(this.Regularsearch))
    if(this.Regularsearch == false){
      this.aval_seat = flight.total_available_seats
      console.log("this.aval_seat",this.aval_seat,formvalue)
      var totalseat = Number(formvalue.adult) + Number(formvalue.child)
      console.log("totalseat",totalseat)
      if (this.aval_seat >= totalseat) {
        var senddata = {
          flight_id: flight.flight_id,
          trip_type: formvalue.trip_type,
          departure_date: formvalue.departure_date,
          return_date: formvalue.return_date,
          adult: formvalue.adult,  children: formvalue.child,  infant: formvalue.infant,
          inventory_user_Type: "self",
          booking_token: JSON.parse(sessionStorage.getItem("booking_token")),
          inv_user:flight.inventory_user_Type
        }
        if (flight.partnervalue == 'patner'){
          console.log("hello partner")
          sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
          sessionStorage.setItem("partnerSearch", JSON.stringify(true));
          // sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
          self.router.navigateByUrl("detail");
        }else if(flight.partnervalue == 'normal'){
          console.log("hello partner")
          sessionStorage.setItem("partnerSearch", JSON.stringify(false));        
          this.dataservice.searchedflightdetail(senddata);
        }        
      } 
      else{
        $('#confirmbooking').modal('show');
      }
    }else{
      this.aval_seat = flight.total_available_seats
      sessionStorage.setItem("Booking_token",JSON.stringify(flight.booking_token))
      var totalseat = Number(formvalue.adult) + Number(formvalue.child)
      if (this.aval_seat >= totalseat) {
          sessionStorage.setItem("partnerSearch", JSON.stringify(true));
          sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
          self.router.navigateByUrl("detail");
      } 
      else{
        $('#confirmbooking').modal('show');
      }
    }
  }

  // only Common




  // departure_city_list() {
  //   this.ngxLoader.start();
  //   const self = this;
  //   self.httpClient.loadingShow = true;
  //   var sendrequestdata = { flight_type: this.flighttype }
  //   this.departure_city_data = [];
  //   this.partnerSearch = '';
  //   this.d_city = [];
  //   this.httpClient.post('v1/search_flight/departure_city_list', sendrequestdata).subscribe((res) => {
  //     self.httpClient.loadingShow = false;
  //     if (res.replyCode == "success" && (res.data).length != 0) {
  //       if (res.data.length != 0) {
  //         this.departure_city_lists = res.data;
  //         self.departure_city_lists.forEach(element => {
  //           self.d_city.push({
  //             id: element.departure_city_id,
  //             text: element.departure_city_name+' <span style="display:none;">'+element.departure_city_code+'</span>',
  //             depcode: element.departure_city_code,
  //             searchtype: 'normal',
  //             additional: { image: element.airport_code, winner: element.airport_name, airportId: element.airport_id }
  //           })
  //         });
  //       }
  //     }
  //     if ((res.replyCode == "success" || res.replyCode == "error") && this.flighttype == 'one_way') {
  //       this.httpClient.get('v1/common/dep_city').subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success") {
  //           if (res.data.length != 0) {
  //             this.departurePartnerList = res.data;
  //             self.departurePartnerList.forEach(element => {
  //               var newdata = this.d_city.find(ele => ele.depcode == element.departure_city_code);
  //               if (newdata == undefined) {
  //                 self.d_city.push({
  //                   id: element.departure_city_id,
  //                   text: element.departure_city_name+' <span style="display:none;">'+element.departure_city_code+'</span>',
  //                   depcode: element.departure_city_code,
  //                   searchtype: 'partner',
  //                   additional: { image: element.airport_code, winner: element.airport_name, airportId: element.airport_id }
  //                 })
  //               } else {
  //                 var index = this.d_city.findIndex(ele => { return (ele.depcode == element.departure_city_code) })
  //                 this.d_city[index].searchtype = 'both';
  //               }
  //             });
  //           }
  //           this.d_city.sort((a, b) => a.text.localeCompare(b.text)); this.departure_city_data = this.d_city;  this.ngxLoader.stop();
  //         }
  //         this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
  //       }, err => {
  //         this.d_city.sort((a, b) => a.text.localeCompare(b.text));  this.departure_city_data = this.d_city;
  //         this.options = { templateResult: this.templateResult, templateSelection: this.templateSelection };
  //         this.ngxLoader.stop();
  //         this.toastr.errorToastr(err.error.message, "Error")
  //       }
  //       );
  //       // when common is commented
  //       // this.ngxLoader.stop();
  //       // this.departure_city_data = this.d_city;
  //       // this.options = { templateResult: this.templateResult,templateSelection: this.templateSelection };
  //     }else{
  //       this.ngxLoader.stop();    
  //       this.departure_city_data = this.d_city;
  //       this.options = { templateResult: this.templateResult,templateSelection: this.templateSelection };
  //     }
  //   }, err => {
  //     this.ngxLoader.stop();
  //     this.toastr.errorToastr(err.error.message, "Error")
  //   }
  //   );
  // }

  // arrival_city_list(id) {
  //   if(this.arrival_city_data){if(this.arrival_city_data.length != 0){  this.arrival_city_data = [];  this.a_city = [];  }}
  //   if (id) {
  //     var datalistfound:any; this.ngxLoader.start();  var filterlist = this.d_city.filter(x => x.id == id);
  //     var sendrequestdata;
  //     if (filterlist[0].searchtype == 'partner') {
  //       this.partnerSearch = 'partner';  var datalist = this.departurePartnerList.filter(x => x.departure_city_id == id);
  //       console.log("filterlist :::::::::::::", this.partnerSearch, datalist, id)
  //       this.departure_city_ids = datalist[0].departure_city_code;
  //       this.searchForm.patchValue({ departure_city_code: id, departure_city_id: datalist[0].departure_city_id, departure_airport_id: datalist[0].airport_id })
  //       const self = this;  self.httpClient.loadingShow = true;
  //       sendrequestdata = {  departure_city_code: datalist[0].departure_city_code,  departure_city_id:datalist[0].departure_city_id  }
  //       this.httpClient.post('v1/common/arr_city', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success" && (res.data).length != 0) {
  //           self.arrivalPartnerList = res.data;  this.Arrivalcity_DATA.length = 0;
  //           self.arrivalPartnerList.forEach(element => {
  //             this.a_city.push({
  //               id: element.arrival_city_id,  text: element.arrival_city_name+' <span style="display:none;">'+element.arrival_city_code+'</span>',  
  //               depcode: element.arrival_city_code,  additional: {  image: element.airport_code,  winner: element.airport_name,  airportId: element.airport_id  }
  //             })
  //           });
  //           this.a_city.sort((a, b) => a.text.localeCompare(b.text));  this.arrival_city_data = this.a_city;
  //           this.options1 = {  templateResult: this.templateResult,  templateSelection: this.templateSelection };
  //         }
  //       });
  //     } else if (filterlist[0].searchtype == 'normal') {
  //       this.partnerSearch = 'normal';  console.log("sadwdwawd", filterlist[0]);  this.departure_city_ids = filterlist[0].id;
  //       this.searchForm.patchValue({ departure_city_id: filterlist[0].id, departure_airport_id: filterlist[0].additional.airportId })
  //       const self = this;  self.httpClient.loadingShow = true;
  //       sendrequestdata = {  flight_type: this.flighttype,  departure_city_id: this.departure_city_ids  }
  //       this.httpClient.post('v1/search_flight/arrival_city_list', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success" && (res.data).length != 0) {
  //           self.arrival_city_lists = res.data;  this.Arrivalcity_DATA.length = 0;
  //           self.arrival_city_lists.forEach(element => {
  //             this.a_city.push({
  //               id: element.arrival_city_id, text: element.arrival_city_name+' <span style="display:none;">'+element.arrival_city_code+'</span>',  
  //               depcode: element.departure_city_code,  additional: {  image: element.airport_code,  winner: element.airport_name,  airportId: element.arrival_airport_id  }
  //             })
  //           });
  //           this.a_city.sort((a, b) => a.text.localeCompare(b.text));  this.arrival_city_data = this.a_city;
  //           this.options1 = {  templateResult: this.templateResult,  templateSelection: this.templateSelection  };
  //         }
  //       });
  //     } else {
  //       this.partnerSearch = 'both';  var datalist = this.departurePartnerList.filter(x => x.departure_city_id == id);
  //       console.log("filterlist :::::::::::::", datalist, id)
  //       this.departure_city_ids = datalist[0].departure_city_code;  this.partner_dep_id = datalist[0].departure_city_id;
  //       this.searchForm.patchValue({ departure_city_code: id, departure_city_id: datalist[0].departure_city_id, departure_airport_id: datalist[0].airport_id })
  //       const self = this;  self.httpClient.loadingShow = true;
  //       sendrequestdata = {  departure_city_code: datalist[0].departure_city_code,  departure_city_id:datalist[0].departure_city_id  }
  //       this.httpClient.post('v1/common/arr_city', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" && (res.data).length != 0) {
  //           self.arrivalPartnerList = res.data;  this.Arrivalcity_DATA.length = 0;
  //           self.arrivalPartnerList.forEach(element => {
  //             this.a_city.push({
  //               id: element.arrival_city_id, text: element.arrival_city_name+' <span style="display:none;">'+element.arrival_city_code+'</span>',  
  //               depcode: element.arrival_city_code,  additional: {  image: element.airport_code,  winner: element.airport_name,  airportId: element.airport_id  }
  //             })
  //           });
  //           this.a_city.sort((a, b) => a.text.localeCompare(b.text));
  //         }
  //         if (res.replyCode == 'success' || res.replyCode == 'error') {
  //           sendrequestdata = {  flight_type: this.flighttype,  departure_city_id: datalist[0].departure_city_id  }
  //           this.httpClient.post('v1/search_flight/arrival_city_list', sendrequestdata).subscribe((res) => {
  //             self.httpClient.loadingShow = false; this.ngxLoader.stop();
  //             if (res.replyCode == "success" && (res.data).length != 0) {
  //               self.arrival_city_lists = res.data;  this.Arrivalcity_DATA.length = 0;
  //               self.arrival_city_lists.forEach(element => {
  //                 if(this.a_city.length != 0){ datalistfound = this.a_city.filter(x => x.id == element.arrival_city_id);  }
  //                 if(datalistfound.length == 0){
  //                   this.a_city.push({
  //                     id: element.arrival_city_id, text: element.arrival_city_name+' <span style="display:none;">'+element.arrival_city_code+'</span>',  
  //                     depcode: element.arrival_city_code,  additional: {  image: element.airport_code,  winner: element.airport_name,  airportId: element.arrival_airport_id  }
  //                   })
  //                 }
  //               });
  //             }
  //             if (res.replyCode == "success" || res.replyCode == "error") {  
  //               this.a_city.sort((a, b) => a.text.localeCompare(b.text));
  //               this.arrival_city_data = this.a_city;
  //               this.options1 = {  templateResult: this.templateResult,  templateSelection: this.templateSelection  };
  //             }
  //           });
  //         }
  //       });
  //     }
  //   }
  // }

  // arrival_date(id) {
  //   if (id) {
  //     var sendrequestdata;  this.ngxLoader.start(); console.log("this.partnerSearch",this.partnerSearch); var normal_dates = [];
  //     if (this.partnerSearch == 'partner') {
  //       var validdates = [];  var datalist = this.a_city.filter(x => x.id == id);
  //       console.log("citylist", this.a_city, "id", id, "result ::::", datalist)
  //       this.arrival_city_ids = datalist.length == 0 ? '' : datalist[0].depcode;
  //       this.searchForm.patchValue({ arrival_city_code: id, arrival_city_id: datalist[0].id, arrival_airport_id: datalist[0].additional.airportId })
  //       const self = this;  self.httpClient.loadingShow = true;
  //       sendrequestdata = { 
  //         departure_city_code: this.departure_city_ids,   departure_city_id:this.searchForm.value.departure_city_id,
  //         arrival_city_code: this.arrival_city_ids,  arrival_city_id:this.searchForm.value.arrival_city_id
  //       }
  //       self.httpClient.post('v1/common/dates', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success" || (res.data).length != 0) {
  //           self.d_date = res.data;  var date_length = res.data.length;
  //           console.log("date length ", date_length)
  //           if (date_length > 0) {
  //             this.minDate = self.d_date[0].flight_date;  this.maxDate = self.d_date[date_length - 1].flight_date
  //           }
  //           if (date_length == 1) {  validdates.push(new Date(this.datePipe.transform(self.d_date[0].flight_date, 'MM/dd/yyyy')))  } 
  //           else if (date_length > 1) {  res.data.forEach(element => {  validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))  });  }
  //           this.myFilter1 = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  }
  //         }
  //       });
  //     } else if (this.partnerSearch == 'normal') {
  //       var filterlist = this.arrival_city_lists.filter(x => x.arrival_city_id == id);  this.arrival_city_ids = filterlist[0].arrival_city_id;
  //       this.searchForm.patchValue({ arrival_city_id: filterlist[0].arrival_city_id, arrival_airport_id: filterlist[0].airport_id })
  //       const self = this;  self.httpClient.loadingShow = true;  var is_rtn;
  //       if (this.flighttype == 'return') {  is_rtn = 1;  } else {  is_rtn = 0;  }
  //       sendrequestdata = {  departure_city_id: this.departure_city_ids,  arrival_city_id: this.arrival_city_ids,  is_return: is_rtn  }
  //       var validdates = []
  //       this.httpClient.post('v1/search_flight/booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success" || (res.data).length != 0) {
  //           self.d_date = res.data;  var date_length = res.data.length;
  //           if (date_length > 0) {
  //             this.minDate = self.d_date[0].flight_date;  this.maxDate = self.d_date[date_length - 1].flight_date  }
  //           res.data.forEach(element => {  validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))  });
  //           this.myFilter1 = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  }
  //         }
  //       });
  //     } else {
  //       var validdates = [];  var datalist = this.a_city.filter(x => x.id == id);
  //       this.arrival_city_ids = datalist[0].depcode;
  //       this.searchForm.patchValue({ arrival_city_id: datalist[0].id, arrival_airport_id: datalist[0].additional.airportId })
  //       // this.searchForm.patchValue({ arrival_city_code:datalist[0].depcode, arrival_city_id: datalist[0].id, arrival_airport_id: datalist[0].additional.airportId })
  //       const self = this;  self.httpClient.loadingShow = true;
  //       sendrequestdata = { 
  //         departure_city_code: this.departure_city_ids, departure_city_id:this.searchForm.value.departure_city_id,
  //         arrival_city_code: this.arrival_city_ids,  arrival_city_id:this.searchForm.value.arrival_city_id
  //       }
  //       self.httpClient.post('v1/common/dates', sendrequestdata).subscribe((res) => {
  //         self.httpClient.loadingShow = false;
  //         if (res.replyCode == "success" || (res.data).length != 0 ) {  
  //           self.d_date = res.data;  var date_length = res.data.length;
  //           if (date_length == 1) {  validdates.push(new Date(this.datePipe.transform(self.d_date[0].flight_date, 'MM/dd/yyyy')))  } 
  //           else if (date_length > 1) {  res.data.forEach(element => {  validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')))  });  }
  //         }
  //         if (res.replyCode == 'success' || res.replyCode == 'error') {
  //           var is_rtn;
  //           if (this.flighttype == 'return') {  is_rtn = 1;  } else {  is_rtn = 0;  }
  //           sendrequestdata = {  departure_city_id: this.partner_dep_id,  arrival_city_id: datalist[0].id,  is_return: is_rtn  }
  //           this.httpClient.post('v1/search_flight/booking_avaliable_date_list', sendrequestdata).subscribe((res) => {
  //             self.httpClient.loadingShow = false;
  //                 if (res.replyCode == "success" || (res.data).length != 0) {
  //                   // self.d_date = res.data;
  //                   var date_length = res.data.length;
  //                   if (date_length > 0) {
  //                     res.data.forEach(element => {
  //                       if (!normal_dates.some((val) => val == element.flight_date)) {
  //                         validdates.push(new Date(this.datePipe.transform(element.flight_date, 'MM/dd/yyyy')));
  //                         this.d_date.push({
  //                           'flight_date': element.flight_date});
  //                       }
  //                     });
  //                   }

  //                 }
  //                 if (res.replyCode == 'success' || res.replyCode == 'error') {
  //                   this.ngxLoader.stop();
  //                   // console.log("ddddddd date ", this.d_date)
  //                   // this.minDate = this.d_date[0].flight_date;  this.maxDate = this.d_date[(this.d_date.length) - 1].flight_date
  //                   // this.myFilter1 = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  }
  //                   setTimeout(() => {
  //                     validdates.sort((a, b) => {  return <any>new Date(a) - <any>new Date(b);  });  this.d_date.sort((a, b) => {  return <any>new Date(a.flight_date) - <any>new Date(b.flight_date);  });
  //                     this.myFilter1 = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  };  this.ngxLoader.stop();
  //                   }, 700);
  //                 }
  //           });
  //         }
  //       });
  //     }
  //   }
  // }

  // searchsubmit() {
  //   this.Airlines = [];  this.Destinations = [];  this.ngxLoader.start();
  //   const self = this;  this.submitted = true;  let formdata = this.searchForm.value;
  //   if (this.partnerSearch == 'partner') {
  //     if (formdata.departure_city_code != "" && formdata.arrival_city_code != "") {
  //       var datarequest = {
  //         dep_city_code: this.departure_city_ids,  arr_city_code: this.arrival_city_ids,
  //         dep_airport_id: this.searchForm.value.departure_airport_id,  arr_airport_id: this.searchForm.value.arrival_airport_id,
  //         departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //         return_date: formdata.return_date,  adult: formdata.adult,  children: formdata.child,  infant: formdata.infant
  //       }
  //       // this.searchForm.patchValue({   departure_city_code:this.departure_city_ids })
  //       this.httpClient.post('v1/common/search', datarequest).subscribe((res: any) => {
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success") {
  //           var sessiondata = {
  //             adult: formdata.adult,  child: formdata.child, infant: formdata.infant, travellers: formdata.travellers,
  //             arrival_city_code: this.arrival_city_ids,  arrival_city_id: formdata.arrival_city_id,  arrival_airport_id: formdata.arrival_airport_id,
  //             departure_airport_id: formdata.departure_airport_id,  departure_city_code: this.departure_city_ids,  departure_city_id: formdata.departure_city_id,
  //             departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,  return_date: formdata.return_date,
  //             trip_type: formdata.trip_type
  //           }
  //           this.to_day = this.searchForm.value.departure_date; var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);
  //           if (this.d_date[findindex - 1] == undefined) {  this.previous_day = '';  } 
  //           else {  this.previous_day = this.d_date[findindex - 1]  }
  //           if (this.d_date[findindex + 1] == undefined) {  this.next_day = '';  } 
  //           else {  this.next_day = this.d_date[findindex + 1]  }

  //           sessionStorage.setItem("search_form", JSON.stringify(sessiondata));
  //           if (res.data.length == 1) {  (res.data[0]).partnervalue = 'patner'  } 
  //           else {  (res.data).forEach(element => {  element.partnervalue = 'patner'  });  }
  //           sessionStorage.setItem("Booking_token", JSON.stringify(res.booking_token));
  //           this.flightdetails_old = res.data;  this.flightdetails = this.flightdetails_old;
  //           (res.data).forEach(element => {
  //             this.Airlines.push({ name: element.airline_name, is_selected: false })
  //             this.Destinations.push({ name: element.arrival_city_name, is_selected: false })
  //           });
  //           this.imagesrc = res.imgBasePath;  this.searchresult = true;  this.renderdata()
  //         } else {
  //           this.flightdetails = '';  this.imagesrc = '';
  //           sessionStorage.removeItem("flights");  sessionStorage.removeItem("imagepath");
  //           self.toastr.errorToastr("Result Not Found", "Error");  this.ngxLoader.stop();
  //         }
  //       },err => {
  //         this.flightdetails = '';  this.imagesrc = '';
  //         this.toastr.errorToastr(err.error.replyMsg, "Error");  this.ngxLoader.stop();
  //       });
  //     }
  //     else {
  //       console.log("search form :::: ", this.searchForm.value)
  //       this.toastr.errorToastr('Fill The Form Correctly.');
  //       this.ngxLoader.stop();
  //     }
  //   } else if (this.partnerSearch == 'normal') {
  //     if (formdata.departure_city_id != "" && formdata.departure_airport_id != '' && formdata.arrival_city_id != "" && formdata.arrival_airport_id != '') {
  //       var sendrequestdata = {
  //         departure_city_id: formdata.departure_city_id,  departure_airport_id: formdata.departure_airport_id,
  //         arrival_city_id: formdata.arrival_city_id,  arrival_airport_id: formdata.arrival_airport_id,
  //         trip_type: formdata.trip_type,
  //         departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //         return_date: formdata.return_date,  adult: formdata.adult,  children: formdata.child,  infant: formdata.infant
  //       }
  //       sessionStorage.setItem("search_form", JSON.stringify(this.searchForm.value));
  //       this.to_day = this.searchForm.value.departure_date; var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);
  //       if (this.d_date[findindex - 1] == undefined) {  this.previous_day = '';  } 
  //       else {  this.previous_day = this.d_date[findindex - 1]  }
  //       if (this.d_date[findindex + 1] == undefined) {  this.next_day = '';  } 
  //       else {  this.next_day = this.d_date[findindex + 1]  }
  //       this.httpClient.post('v1/search_flight/search_ticket', sendrequestdata).subscribe((res: any) => {
  //         if (res.replyCode == "success" || res.replyCode == "error") {  this.ngxLoader.stop();  }
  //         if (res.replyCode == "success") {
  //           if (res.data.length == 1) {  (res.data[0]).partnervalue = 'normal'  } 
  //           else {  (res.data).forEach(element => {  element.partnervalue = 'normal'  });  }
  //           this.flightdetails_old = res.data;  this.flightdetails = this.flightdetails_old;
  //           (res.data).forEach(element => {
  //             this.Airlines.push({ name: element.airline_name, is_selected: false })
  //             this.Destinations.push({ name: element.arrival_city_name, is_selected: false })
  //           });
  //           this.imagesrc = res.imgBasePath;  this.searchresult = true;  this.renderdata()
  //         } else {
  //           this.flightdetails = '';  this.imagesrc = '';
  //           sessionStorage.removeItem("flights");  sessionStorage.removeItem("imagepath");
  //           self.toastr.errorToastr("Result Not Found", "Error");  this.ngxLoader.stop();
  //         }
  //       }, err => {
  //         this.flightdetails = '';  this.imagesrc = '';
  //         this.toastr.errorToastr(err.error.replyMsg, "Error");  this.ngxLoader.stop();
  //       });
  //     }
  //     else {
  //       console.log("search form :::: ", this.searchForm.value)
  //       this.toastr.errorToastr('Fill The Form Correctly.');  this.ngxLoader.stop();
  //     }
  //   } else {
  //     if (formdata.departure_city_code != "" && formdata.arrival_city_code != "") {
  //       var datarequest = {
  //         dep_city_code: this.departure_city_ids,  arr_city_code: this.arrival_city_ids,
  //         departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //         return_date: formdata.return_date,
  //         adult: formdata.adult,  children: formdata.child,  infant: formdata.infant,
  //         dep_airport_id: this.searchForm.value.departure_airport_id,  arr_airport_id: this.searchForm.value.arrival_airport_id
  //       }
  //       this.to_day = this.searchForm.value.departure_date; var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);
  //       if (this.d_date[findindex - 1] == undefined) {  this.previous_day = '';  } 
  //       else {  this.previous_day = this.d_date[findindex - 1]  }
  //       if (this.d_date[findindex + 1] == undefined) {  this.next_day = '';  } 
  //       else {  this.next_day = this.d_date[findindex + 1]  }
  //       // sessionStorage.setItem("search_form", JSON.stringify(this.searchForm.value));
  //       this.httpClient.post('v1/common/search', datarequest).subscribe((res: any) => {
  //         if (res.replyCode == "success") {
  //           sessionStorage.setItem("Booking_token", JSON.stringify(res.booking_token));
  //           this.flightdetails_old = res.data;
  //           this.flightdetails = this.flightdetails_old;
  //           (res.data).forEach(element => {
  //             this.Airlines.push({ name: element.airline_name, is_selected: false })
  //             this.Destinations.push({ name: element.arrival_city_name, is_selected: false })
  //           });
  //           if (res.data.length == 1) {
  //             (res.data[0]).partnervalue = 'patner'
  //           } else {
  //             (res.data).forEach(element => {
  //               element.partnervalue = 'patner'
  //             });
  //           }
  //           this.imagesrc = res.imgBasePath;
  //           this.searchresult = true;
  //         }


  //         if (res.replyCode == 'success' || res.replyCode == 'error') {
  //           if (formdata.departure_city_id != "" && formdata.departure_airport_id != '' && formdata.arrival_city_id != "" && formdata.arrival_airport_id != '') {
  //             var sendrequestdata = {
  //               departure_city_id: formdata.departure_city_id,
  //               departure_airport_id: formdata.departure_airport_id,
  //               arrival_city_id: formdata.arrival_city_id,
  //               arrival_airport_id: formdata.arrival_airport_id,
  //               trip_type: formdata.trip_type,
  //               departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //               return_date: formdata.return_date,
  //               adult: formdata.adult,
  //               children: formdata.child,
  //               infant: formdata.infant
  //             }
  //             // this.to_day = this.searchForm.value.departure_date;
  //             // var findindex = this.d_date.findIndex(e => e.flight_date == this.to_day);

  //             // if (this.d_date[findindex - 1] == undefined) {
  //             //   this.previous_day = '';
  //             // } else {
  //             //   this.previous_day = this.d_date[findindex - 1]
  //             // }
  //             // if (this.d_date[findindex + 1] == undefined) {
  //             //   this.next_day = '';
  //             // } else {
  //             //   this.next_day = this.d_date[findindex + 1]
  //             // }
  //             this.httpClient.post('v1/search_flight/search_ticket', sendrequestdata).subscribe((res: any) => {
  //               if (res.replyCode == "success" || res.replyCode == "error") {
  //                 this.ngxLoader.stop();
  //               }
  //               if (res.replyCode == "success") {
  //                 var sessiondata = {
  //                   adult: formdata.adult,
  //                   arrival_city_code: this.arrival_city_ids,
  //                   child: formdata.child,
  //                   departure_airport_id: formdata.departure_airport_id,
  //                   departure_city_code: this.departure_city_ids,
  //                   departure_city_id: formdata.departure_city_id,
  //                   arrival_city_id: formdata.arrival_city_id,
  //                   arrival_airport_id: formdata.arrival_airport_id,
  //                   departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //                   infant: formdata.infant,
  //                   return_date: formdata.return_date,
  //                   travellers: formdata.travellers,
  //                   trip_type: formdata.trip_type
  //                 }
  //                 if (res.data.length == 1) {
  //                   (res.data[0]).partnervalue = 'normal'
  //                 } else {
  //                   (res.data).forEach(element => {
  //                     element.partnervalue = 'normal'
  //                   });
  //                 }
  //                 sessionStorage.setItem("search_form", JSON.stringify(sessiondata));
  //                 (res.data).forEach(element => {
  //                   this.flightdetails.push(element)
  //                   this.Airlines.push({ name: element.airline_name, is_selected: false })
  //                   this.Destinations.push({ name: element.arrival_city_name, is_selected: false })
  //                 });

  //                 this.imagesrc = res.imgBasePath;
  //                 this.searchresult = true;
  //                 this.renderdata()
  //               } else {
  //                 var sessiondata = {
  //                   adult: formdata.adult,
  //                   arrival_city_code: this.arrival_city_ids,
  //                   child: formdata.child,
  //                   departure_airport_id: formdata.departure_airport_id,
  //                   departure_city_code: this.departure_city_ids,
  //                   departure_city_id: formdata.departure_city_id,
  //                   arrival_city_id: formdata.arrival_city_id,
  //                   arrival_airport_id: formdata.arrival_airport_id,
  //                   departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //                   infant: formdata.infant,
  //                   return_date: formdata.return_date,
  //                   travellers: formdata.travellers,
  //                   trip_type: formdata.trip_type
  //                 }
  //                 sessionStorage.setItem("search_form", JSON.stringify(sessiondata));
  //               }
  //             }, err => {
  //               // this.flightdetails = '';
  //               var sessiondata = {
  //                 adult: formdata.adult,
  //                 arrival_city_code: this.arrival_city_ids,
  //                 child: formdata.child,
  //                 departure_airport_id: formdata.departure_airport_id,
  //                 departure_city_code: this.departure_city_ids,
  //                 departure_city_id: formdata.departure_city_id,
  //                 arrival_city_id: formdata.arrival_city_id,
  //                 arrival_airport_id: formdata.arrival_airport_id,
  //                 departure_date: this.searchdate != '' ? this.searchdate : formdata.departure_date,
  //                 infant: formdata.infant,
  //                 return_date: formdata.return_date,
  //                 travellers: formdata.travellers,
  //                 trip_type: formdata.trip_type
  //               }
  //               sessionStorage.setItem("search_form", JSON.stringify(sessiondata));
  //               this.imagesrc = '';
  //               this.toastr.errorToastr(err.error.replyMsg, "Error");
  //               this.ngxLoader.stop();
  //             });
  //           }
  //         }
  //       }, err => {
  //         this.flightdetails = '';
  //         this.imagesrc = '';
  //         this.toastr.errorToastr(err.error.replyMsg, "Error");
  //         this.ngxLoader.stop();
  //       });

  //     }
  //     else {
  //       console.log("search form :::: ", this.searchForm.value)
  //       this.toastr.errorToastr('Fill The Form Correctly.');
  //       this.ngxLoader.stop();
  //     }
  //   }

  // }

  // bookflight(flight) {
  //   const self = this;  var formvalue = this.searchForm.value
  //   this.aval_seat = flight.total_available_seats;  var totalseat = Number(formvalue.adult) + Number(formvalue.child)
  //   if (this.aval_seat >= totalseat) {
  //     var senddata = {
  //       flight_id: flight.flight_id,  trip_type: formvalue.trip_type,
  //       departure_date: formvalue.departure_date,  return_date: formvalue.return_date,
  //       adult: formvalue.adult,  children: formvalue.child,  infant: formvalue.infant,
  //       live_pnr: flight.live_pnr,  inventory_user_Type: "self"
  //     }
  //     if (flight.partnervalue == 'patner') {
  //       sessionStorage.setItem("searchedflights_detail", JSON.stringify(flight));
  //       sessionStorage.setItem("partnerSearch", JSON.stringify(true));
  //       // sessionStorage.setItem("imagepath", JSON.stringify(self.srcimagepath));
  //       self.router.navigateByUrl("detail");
  //     } else {
  //       sessionStorage.setItem("partnerSearch", JSON.stringify(false));
  //       this.dataservice.searchedflightdetail(senddata);
  //     }
  //   } else {  $('#confirmbooking').modal('show');  }
  // }

  changestatus(label) {
    if (label == 'arrivaldate_input') {
      this.submitted = false;  this.searchForm.patchValue({ departure_city_id: '', departure_date: '', return_date: '' });  this.arrivaldate_input = true
    } else if (label == 'departure_date') {
      this.submitted = false;  this.searchForm.patchValue({ departure_date: '', return_date: '' });  this.departure_date = true
    } else if (label == 'return_date_input') {
      this.submitted = false;  this.return_date_input = true
    } else if (label == 'traveller_input') {
      this.submitted = false;  this.traveller_input = true
    }
  }

  set_d_date(val) {
    this.searchForm.patchValue({ return_date: '' });  var currentdate = this.datePipe.transform(val, 'yyyy-MM-dd');
    this.searchForm.patchValue({ departure_date: this.datePipe.transform(val, 'yyyy-MM-dd') });
    if (this.flighttype == 'return') {
      var sendrequestdata = {
        departure_city_code: this.departure_city_ids,   arrival_city_code: this.arrival_city_ids ,is_return:this.flighttype == 'one_way'?0:1,
        departure_city_id:this.searchForm.value.departure_city_id,  arrival_city_id:this.searchForm.value.arrival_city_id,  
        departure_date: this.datePipe.transform(val, 'yyyy-MM-dd'),
      }
      var validdates = []
      this.httpClient.post('v1/common/return_dates', sendrequestdata).subscribe((res) => {
        this.httpClient.loadingShow = false;
        if (res.replyCode == "success") {
          res.data.forEach(element => {
            // var nwdate = new Date(currentdate);
            // var newdate = nwdate.setDate(nwdate.getDate() + element.return_in_days);
            var return_d_date = this.datePipe.transform(element.flight_date, 'MM/dd/yyyy');
            validdates.push(new Date(return_d_date))
          });
          var date_length = validdates.length;
          if (date_length > 0) {
            this.return_min_date = this.datePipe.transform(validdates[0], 'yyyy-MM-dd');
            this.return_max_date = this.datePipe.transform(validdates[date_length - 1], 'yyyy-MM-dd');
          }
          this.myFilter = (d: Date): boolean => {  const time = d.getTime();  return validdates.find(x => x.getTime() == time);  }
        }
      });
    }
  }

  set_r_date(val) {  this.searchForm.patchValue({ return_date: this.datePipe.transform(val, 'yyyy-MM-dd') });  }

  Change_Status(val) {
    this.initiate()
    if (val == 'oneway') {
      this.disableInput = true;  this.return_date = '';  this.flighttype = 'one_way';
      this.searchForm.patchValue({  trip_type: '0',  adult: 1,  child: 0,  travellers: 1,  infant: 0  });
      this.return_date_input = false;  this.departure_city_list();  this.arrival_city_data = [];
    } else {
      this.flighttype = 'return';  this.disableInput = null;
      this.searchForm.patchValue({  trip_type: '1',  adult: 1,  child: 0,  travellers: 1,  infant: 0  });
      this.departure_city_list();  this.arrival_city_data = [];
    }
  }

  open_popup() {  $('#totalpassengers').modal('show');  }

  traveller() {
    var adult = this.travellers.value.adult;  var child = this.travellers.value.child;  var infant = this.travellers.value.infant;
    if (adult != '' && child != '' && infant != '') {
      if (parseInt(adult) >= parseInt(infant)) {
        this.total = parseInt(adult) + parseInt(child) + parseInt(infant);
        this.searchForm.patchValue({  adult: parseInt(adult),  child: parseInt(child),  infant: parseInt(infant)  });
        $('#totalpassengers').modal('hide');  this.searchForm.patchValue({ travellers: this.total });
      } else {  alert('Please select infant less than adult');  }
    } else {  alert('All Field is required');  }
  }

  searchData() {
    alert(JSON.stringify(this.searchForm.value));
    const self = this;  self.httpClient.loadingShow = true;
    this.httpClient.post('searchFlights', this.searchForm.value).subscribe((res) => {
      self.httpClient.loadingShow = false;
      if (res.success) {
        localStorage.setItem("user_email", JSON.stringify(res.data));
        localStorage.setItem("type", 'register');  self.flight = res.data;  $('#success').show();
        setTimeout(function () {  $('#success').hide();  }, 2000);
      } else {
        $('#error').show();  setTimeout(function () {  $('#error').hide();  }, 4000);
      }
    });
  }
  

  ngAfterViewInit() {
    this.setInitialValue();
  }
  ngOnDestroy() {
    window.removeEventListener('scroll', this.scroll, true);
    this._onDestroy.next();
    this._onDestroy.complete();

    this._onDestroy1.next();
    this._onDestroy1.complete();
  }
  protected setInitialValue() {
    this.filtered_departurecity
      .pipe(take(1), takeUntil(this._onDestroy))
      .subscribe(() => {
        this.departureSelect.compareWith = (a: departurecity_lists, b: departurecity_lists) => a && b && a.id === b.id;
      });
  }
  protected filterDeparturecity() {
    if (!this.Departurecity_DATA) {  return;  }
    let search = this.departureFilterCtrl.value;
    if (!search) {  this.filtered_departurecity.next(this.Departurecity_DATA.slice());  return;  } 
    else {  search = search.toLowerCase();  }
    this.filtered_departurecity.next(  this.Departurecity_DATA.filter(a => a.departure_city_name.toLowerCase().indexOf(search) > -1)  );
  }

  protected filterarrivalcity() {
    if (!this.Arrivalcity_DATA) {
      return;
    }
    let search = this.arrivalFilterCtrl.value;
    if (!search) {
      this.filtered_arrivalcity.next(this.Arrivalcity_DATA.slice());
      return;
    } else {
      search = search.toLowerCase();
    }
    this.filtered_arrivalcity.next(
      this.Arrivalcity_DATA.filter(d_city => d_city.arrival_city_name.toLowerCase().indexOf(search) > -1)
    );
  }

  openNav() {  document.getElementById("myNav").style.width = "250px";  }

  closeNav() {  document.getElementById("myNav").style.width = "0%";  }

}

export interface JQuery {}
export interface arrivalcity_lists {
  id: number;
  arrival_city_id: number;
  arrival_city_name: string;
  arrival_city_code: number;
  airport_name: string;
  airport_id: number;
}
export interface departurecity_lists {
  id: number;
  departure_city_id: number;
  departure_city_name: string;
  departure_city_code: number;
  airport_name: string;
  airport_id: number;

}


import { DealComponent } from './home/deal/deal.component';
import { NgModule, Component } from '@angular/core';
import { Routes, RouterModule, PreloadAllModules } from '@angular/router';
import { DashmasterComponent } from './dashboardlayout/dashmaster/dashmaster.component';
import { AdminmasterComponent } from './adminlayout/adminmaster/adminmaster.component';
import { LayoutmasterComponent } from './layout/layoutmaster/layoutmaster.component';
import { AuthGuardService,HomeGuardService } from './services';

const routes: Routes = [
  {
    path: '',
    // redirectTo: 'auth/login',
    redirectTo: 'firstpage',
    pathMatch: 'full'
  }
  ,{
    path: 'dashboard',
    component:DashmasterComponent,
    canActivate: [AuthGuardService],
    children:[
      { 
      path: '', loadChildren: () => import('./homedashboard/homedashboard.module').then(m => m.HomedashboardModule) 
    }, 
    ]
  }
  ,
  { 
    path: '',
    component:DashmasterComponent,
    children:[
      { 
      path: '', loadChildren: () => import('./home/home.module').then(m => m.HomeModule) 
    }, 
    ]},
    { 
      path: '',
      component:LayoutmasterComponent, 
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }, 
        { path: 'homepage', loadChildren: () => import('./shome/shome.module').then(m => m.ShomeModule) }, 
      ]
    },
    { 
      path: 'clientdashboard',
      component:LayoutmasterComponent, 
      canActivate: [AuthGuardService],
      children:[
        { path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule) }, 
        { path: '', loadChildren: () => import('./shome/shome.module').then(m => m.ShomeModule) }, 
      ]},
    { 
    path: '', 
    component:DashmasterComponent, 
    canActivate: [AuthGuardService],
    children:[ 
      { 
        path: '', 
        loadChildren: () => import('./route-file/route-file.module').then(m => m.RouteConfiqFileModule) 
      }
    ]
  },{ 
    path: '', component:AdminmasterComponent, 
    canActivate: [AuthGuardService],
    children:[
      { 
        path: '', 
        loadChildren: () => import('./admin/admin.module').then(m => m.AdminModule) 
      }
    ]
  },
];


@NgModule({
  imports: [RouterModule.forRoot(routes, {
		useHash: false,
		enableTracing: false,
 
		preloadingStrategy: PreloadAllModules
	})],
  exports: [RouterModule]
})
export class AppRoutingModule { }

import { createEntityAdapter, EntityAdapter, EntityState } from '@ngrx/entity';
import { PlanList } from '../../modal';

export interface PlanListState extends EntityState<PlanList> {
    isLoading: boolean;
    selectedPlanListId: any;
    error: any;
}

export const PlanListAdapter: EntityAdapter<PlanList> = createEntityAdapter<PlanList>({
  selectId: (editorder: PlanList) => 'data',
  sortComparer: false,
});

export const orderInitialState: PlanListState = PlanListAdapter.getInitialState({
  isLoading: true,
  selectedPlanListId: null,
  error: null
});

export const selectedPlanListId = (state: PlanListState) => state.entities;
export const selectIsLoading = (state: PlanListState) => state.isLoading;
export const selectError = (state: PlanListState) => state.error;

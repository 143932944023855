import { CUSTOM_ELEMENTS_SCHEMA, NgModule } from '@angular/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { MAT_DATE_LOCALE, MatSelectModule } from '@angular/material';

import { AdminlayoutModule } from './adminlayout/adminlayout.module';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { AppStoreModule } from './modal/app/app-store.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';
import { CheckoutModule } from 'paytm-blink-checkout-angular';
import { CommonModule } from '@angular/common';
import { DashboardlayoutModule } from './dashboardlayout/dashboardlayout.module';
import { HomedashboardModule } from './homedashboard/homedashboard.module';
import { HttpClientModule } from '@angular/common/http';
import { LayoutModule } from './layout/layout.module';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatInputModule } from '@angular/material/input';
import {MatSortModule} from '@angular/material/sort';
import { Ng2SearchPipeModule } from 'ng2-search-filter';
import { NgxMaterialTimepickerModule } from 'ngx-material-timepicker';
import { NgxUiLoaderModule } from 'ngx-ui-loader';
import { OrderByPipe } from './services/order-by.pipe';
import { RootStoreModule } from './root-store';
import { ToastrModule } from 'ng6-toastr-notifications';
import { environment } from "../environments/environment";

// import { initializeApp } from 'firebase/app';
// initializeApp(environment.firebase);



@NgModule({
  declarations: [
    AppComponent,
    OrderByPipe,
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    LayoutModule,
    MatInputModule,
    DashboardlayoutModule,
    AdminlayoutModule,
    ToastrModule.forRoot(),
    RootStoreModule,
    AppStoreModule,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatSelectModule,
    MatFormFieldModule,
    MatSortModule,
    HomedashboardModule,
    NgxUiLoaderModule,
    NgxMaterialTimepickerModule,
    CheckoutModule,
    Ng2SearchPipeModule
  ],
  providers: [ { provide: MAT_DATE_LOCALE, useValue: 'en-GB' }],
  bootstrap: [AppComponent],
  exports:[OrderByPipe,Ng2SearchPipeModule],
  schemas: [
    CUSTOM_ELEMENTS_SCHEMA
  ],
})
export class AppModule { }
